import {
  STORE_LOADING,
  STORE_LOAD_ERROR,
  GET_CHIP_PRODUCTS,
  GET_SEARCH_PRODUCTS,
  GET_CROP_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_CHECKOUT_PRODUCTS,
  GET_CHECKOUT_CUSTOMER,
  CHECKOUT_USER_VALIDATE,
  APPLY_DISCOUNT_VALIDATE,
  PURCHASE_VALIDATE,
  PURCHASE_FINALIZE,
  TOGGLE_DISCOUNT_DIALOG,
  GET_DISCOUNT_TYPES,
  GET_DISCOUNT_TYPE,
  SAVE_DISCOUNT,
} from './actions';

const initialState = {
  columns: [],
  orderColumns: [],
  discounts: [],
  discount: [],
  orders: [],
  layouts: null,
  loading: false,
  loadError: false,
  errorData: null,
  productArray: [],
  customerArray: [],
  discountArray: [],
  purchaseArray: [],
  finalPurchaseArray: [],
  discountCodeDialogOpen: false,
};

export default function (state = initialState, action) {
  const { type, columns, data } = action;
  switch (type) {
    case STORE_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case STORE_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_CHIP_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_SEARCH_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_CROP_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_CHECKOUT_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_CHECKOUT_CUSTOMER:
      return {
        ...state,
        customerArray: data,
      };
    case CHECKOUT_USER_VALIDATE:
      return {
        ...state,
        customerArray: data,
      };
    case APPLY_DISCOUNT_VALIDATE:
      return {
        ...state,
        discountArray: data,
      };
    case PURCHASE_VALIDATE:
      return {
        ...state,
        purchaseArray: data,
      };
    case PURCHASE_FINALIZE:
      return {
        ...state,
        finalPurchaseArray: data,
      };
    case TOGGLE_DISCOUNT_DIALOG:
      return {
        ...state,
        discount: data,
        discountCodeDialogOpen: !state.discountCodeDialogOpen,
      };
    case GET_DISCOUNT_TYPES:
      return {
        ...state,
        columns,
        discounts: data,
      };
    case GET_DISCOUNT_TYPE:
      return {
        ...state,
        discount: data,
      };
    case SAVE_DISCOUNT:
      return {
        ...state,
        discount: data,
      };
    default:
      return state;
  }
}
