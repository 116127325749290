import { graphQlFetch } from '../../../Utilities/Utilities';

export const MANA_LOADING = 'MANA_LOADING';
export const managementLoadingAction = () => ({
  type: MANA_LOADING,
});
export const MANA_LOAD_ERROR = 'MANA_LOAD_ERROR';
export const managementLoadingErrorAction = (trigger, error) => ({
  type: MANA_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const TOGGLE_BUSINESS_DIALOG = 'TOGGLE_BUSINESS_DIALOG';
export const toggleBusinessDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }

    return dispatch({
      type: TOGGLE_BUSINESS_DIALOG,
      data: rowData,
    });
  }
);

export const GET_BUSINESSES = 'GET_BUSINESSES';
export const getBusinesses = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const query = `{
      businesses {
        items {
          id
          uuid
          name
          mfr_id
          tax_code
          city
          state
          zip_code
          address
          phone
          email
          type
          kit_prefix
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.businesses.items) {
      const businessArray = await result.data.businesses.items.filter((x) => !!x);

      return dispatch({
        type: 'GET_BUSINESSES',
        data: businessArray,
        columns: [
          { title: 'Name', field: 'name' },
          { title: 'Email', field: 'email' },
          { title: 'City', field: 'city' },
          { title: 'State', field: 'state' },
          { title: 'MFR ID', field: 'mfr_id' },
          { title: 'Prefix', field: 'kit_prefix' },
        ],
      });
    }
    if (await !result.data.businesses.items) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS';
export const getBusinessUsers = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const query = `{
      users {
        items {
          id
          uuid
          name
          mail
          roles {
            role
          }
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.users.items) {
      const businessUsersArray = await result.data.users.items.filter((x) => !!x);

      return dispatch({
        type: 'GET_BUSINESS_USERS',
        data: businessUsersArray,
      });
    }
    if (await !result.data.users.items) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_BUSINESS = 'GET_BUSINESS';
export const getBusiness = (access, data) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_business/adapify_business_resource?business_id=${data.id}`, requestOptions);
    const result = await JSON.parse(await response.text());

    const businessResultArray = [];
    Object.entries(result.business).forEach((entry) => {
      const [key, value] = entry;
      if (value[0] && value[0].value) { // for single value fields
        businessResultArray[key] = value[0].value;
      } else if (value[0] && value[0].target_id) { // for multi value reference fields
        const referenceArray = [];
        Object.entries(value).forEach((entry) => {
          referenceArray.push(entry[1].target_uuid);// referenceArray.push(entry[1].target_id);
        });
        businessResultArray[key] = referenceArray;
      }
    });

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_BUSINESS',
        data: businessResultArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const EDIT_BUSINESS = 'EDIT_BUSINESS';
export const editBusiness = (access, business) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let managerJson = '[';
    if (business.manager && business.manager.length > 0) {
      business.manager.forEach((mgr) => {
        managerJson += `{"type": "user--user","id": "${mgr}"},`;
      });
      managerJson = managerJson.slice(0, -1); // json formatting, removes final comma
    } managerJson += ']';

    let employeeJson = '[';
    if (business.employee && business.employee.length > 0) {
      business.employee.forEach((emp) => {
        employeeJson += `{"type": "user--user","id": "${emp}"},`;
      });
      employeeJson = employeeJson.slice(0, -1); // json formatting, removes final comma
    } employeeJson += ']';

    let body = `{
      "data": {
        "id": "${business.uuid}",
        "type": "adapify_business--adapify_business",
        "attributes": {
          "name": "${business.name}",
          "email": "${business.email}",
          "mfr_id": "${business.mfr_id ? business.mfr_id : ''}",
          "tax_code": "${business.tax_code ? business.tax_code : ''}",
          "address": "${business.address ? business.address : ''}",
          "city": "${business.city ? business.city : ''}",
          "state": "${business.state ? business.state : ''}",
          "zip_code": "${business.zip_code ? business.zip_code : ''}",
          "phone": "${business.phone ? business.phone : ''}",
          "type": "${business.type ? business.type : ''}",
          "kit_prefix": "${business.kit_prefix ? business.kit_prefix : ''}"
        },
        "relationships": {
          "business_managers": {
            "data": []
          },
          "business_employees": {
            "data": []
          }
        }
      }
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_business/adapify_business/${business.uuid}`, requestOptions);

    let relBody = `{
      "data": {
        "id": "${business.uuid}",
        "type": "adapify_business--adapify_business",
        "attributes": {
          "name": "${business.name}"
        },
        "relationships": {
          "business_managers": {
            "data": ${managerJson}
          },
          "business_employees": {
            "data": ${employeeJson}
          }
        }
      }
    }`;

    relBody = JSON.stringify(JSON.parse(relBody));
    const relrequestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: relBody,
      redirect: 'follow',
    };
    const resultRelationship = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_business/adapify_business/${business.uuid}`, relrequestOptions);

    if ((result.status === 200 && resultRelationship.status === 200)) {
      await dispatch(getBusinesses(access)); // need to await update on table
      return dispatch({
        type: 'EDIT_BUSINESS',
        data: business.uuid,
      });
    }
    if (result.ok === false || resultRelationship.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_BUSINESS = 'SAVE_BUSINESS';
export const saveBusiness = (access, business) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let managerJson = '[';
    if (business.manager && business.manager.length > 0) {
      business.manager.forEach((mgr) => {
        managerJson += `{"type": "user--user","id": "${mgr}"},`;
      });
      managerJson = managerJson.slice(0, -1); // json formatting, removes final comma
    } managerJson += ']';

    let employeeJson = '[';
    if (business.employee && business.employee.length > 0) {
      business.employee.forEach((emp) => {
        employeeJson += `{"type": "user--user","id": "${emp}"},`;
      });
      employeeJson = employeeJson.slice(0, -1); // json formatting, removes final comma
    } employeeJson += ']';

    let body = `{
      "data": {
        "type": "adapify_business--adapify_business",
        "attributes": {
          "name": "${business.name}",
          "mfr_id": "${business.mfr_id}",
          "tax_code": "${business.tax_code}",
          "city": "${business.city}",
          "state": "${business.state}",
          "zip_code": "${business.zip_code}",
          "address": "${business.address}",
          "phone": "${business.phone}",
          "email": "${business.email}",
          "type": "${business.type}",
          "kit_prefix": "${business.kit_prefix}"
        },
        "relationships": {
          "business_managers": {
            "data": ${managerJson}
          },
          "business_employees": {
            "data": ${employeeJson}
          }
        }
      }
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_business/adapify_business`, requestOptions);

    if (result.status === 200 || result.status === 201) {
      await dispatch(getBusinesses(access)); // need to await update on table
      return dispatch({
        type: 'SAVE_BUSINESS',
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);
