import {
  MANA_LOADING,
  MANA_LOAD_ERROR,
  CREATE_USER,
  GET_USERS,
  GET_USER,
  TOGGLE_USER_DIALOG,
  TOGGLE_EDIT_DIALOG,
  VALIDATE_EMAIL,
  EDIT_USER,
} from './actions';

const initialState = {
  columns: [],
  users: [],
  user: [],
  userDialogOpen: false,
  editDialogOpen: false,
  loading: false,
  loadError: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const { type, columns, data } = action;
  switch (type) {
    case MANA_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case MANA_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_USERS:
      return {
        ...state,
        columns,
        users: data,
      };
    case GET_USER:
      return {
        ...state,
        user: data,
      };
    case TOGGLE_USER_DIALOG:
      return {
        ...state,
        user: data,
        userDialogOpen: !state.userDialogOpen,
      };
    case TOGGLE_EDIT_DIALOG:
      return {
        ...state,
        user: data,
        editDialogOpen: !state.editDialogOpen,
      };
    case CREATE_USER:
      return {
        ...state,
      };
    case EDIT_USER:
      return {
        ...state,
      };
    case VALIDATE_EMAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
