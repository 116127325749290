import { editUser } from '../../Management/Users/Users/redux/actions';

export const REGISTER_USER = 'REGISTER_USER';
export const registerUser = (
  username,
  email,
  pass,
  setMessageOn,
  setMessage,
  setStatus,
  clearForm,
) => (
  async function (dispatch) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/hal+json');

    const body = JSON.stringify({
      _links: {
        type: {
          href: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/rest/type/user/user`,
        },
      },
      name: {
        value: `${username}`,
      },
      mail: {
        value: `${email}`,
      },
      pass: {
        value: `${pass}`,
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/user/register?_format=hal_json`, requestOptions);
    const result = await response.json();
    if (result.message) {
      if (result.message.includes('No password')) {
        setStatus('error');
        setMessage(result.message);
        setMessageOn(true);
      } else {
        const error = 'NO';
        setStatus('error');
        setMessage(error);
        setMessageOn(true);
      }
    } else {
      clearForm();
      setStatus('success');
      setMessage('Your Account has been created. Please login.');
      setMessageOn(true);
      // call edit user
      return dispatch(editUser('', result.name[0].value, '', '', result.uid[0].value));
    }
    return {
      type: 'REGISTER_USER',
    };
  }
);

export const FORGOT_PASS = 'FORGOT_PASS';
export const forgotPass = (email, setMessageOn, setMessage, setStatus, clearForm) => (
  async function () {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const body = JSON.stringify({
      mail: {
        value: `${email}`,
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/service/password/reset?_format=json`, requestOptions);
    const result = await response.json();
    if (result.message) {
      if (result.message.includes('not found')) {
        setStatus('error');
        setMessage(result.message);
        setMessageOn(true);
      } else {
        let error = '';
        const message = result.message.split('\n');
        message.map((item, i) => {
          if (i === 1) {
            let itemArray = [];
            itemArray = item.split(': ');
            error += `${itemArray[1]}`;
          }
          return error;
        });
        setStatus('error');
        setMessage(error);
        setMessageOn(true);
      }
    } else {
      clearForm();
      setStatus('success');
      setMessage('Please follow the instructions in the email we sent to reset your password');
      setMessageOn(true);
    }
    return {
      type: 'FORGOT_PASS',
    };
  }
);

export const UPDATE_PASS = 'UPDATE_PASS';
export const updatePass = (
  uid,
  timestamp,
  hash,
  pass,
  setMessageOn,
  setMessage,
  setStatus,
  clearForm,
) => (
  async function () {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const body = `{
      "uid": "${uid}",
      "timestamp": "${timestamp}",
      "hash": "${hash}",
      "pass": "${pass}"
    }`;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/service/user/reset?_format=json`, requestOptions);
    const result = await response.json();
    if (result.message) {
      setStatus('error');
      setMessage(result.message);
      setMessageOn(true);
    } else {
      clearForm();
      setStatus('success');
      setMessage('Your password has been set. Please login.');
      setMessageOn(true);
    }
    return {
      type: 'UPDATE_PASS',
    };
  }
);
