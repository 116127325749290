import {
  REGISTER_USER,
  FORGOT_PASS,
  UPDATE_PASS,
} from './actions';

const initialState = {};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case REGISTER_USER:
      return {
        ...state,
      };
    case FORGOT_PASS:
      return {
        ...state,
      };
    case UPDATE_PASS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
