import {
  GET_PRODUCT,
  GET_PRODUCTS,
  TOGGLE_PRODUCT_DIALOG,
  PROD_LOADING,
  PROD_LOAD_ERROR,
  EDIT_PROD,
  SAVE_PROD,
  GET_ARCHIVED_PRODUCTS,
  GET_ALL_ORDERS,
  GET_CUSTOMER_ORDERS,
  SET_ORDER_STATUS,
  SET_ORDERS_SHIPPED,
  GET_MERGED_PDF,
} from './actions';

const initialState = {
  columns: [],
  product: [],
  order: '',
  products: [],
  productArray: [],
  orderArray: [],
  orderColumns: [],
  productDialogOpen: false,
  loading: false,
  loadError: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const {
    type,
    columns,
    data,
    orderColumns,
  } = action;
  switch (type) {
    case PROD_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case PROD_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: data,
      };
    case TOGGLE_PRODUCT_DIALOG:
      return {
        ...state,
        product: data,
        productDialogOpen: !state.productDialogOpen,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        columns,
        products: data,
      };
    case EDIT_PROD:
      return {
        ...state,
        product: data,
      };
    case SAVE_PROD:
      return {
        ...state,
        product: data,
      };
    case GET_ARCHIVED_PRODUCTS:
      return {
        ...state,
        productArray: data,
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        orderColumns,
        orderArray: data,
      };
    case GET_CUSTOMER_ORDERS:
      return {
        ...state,
        orderArray: data,
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        order: data,
      };
    case SET_ORDERS_SHIPPED:
      return {
        ...state,
      };
    case GET_MERGED_PDF:
      return {
        ...state,
      };
    default:
      return state;
  }
}
