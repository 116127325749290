// React Redux Core
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  SwipeableDrawer,
  IconButton,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import StoreIcon from '@mui/icons-material/Store';
import theme from '../theme';

const SideMenu = () => {
  // const classes = useStyles();
  const [state, setState] = useState({ left: false });
  const user = useSelector((state) => state.utilities.currentUser);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const links = (
    <>
      {((user !== null) && (user.uid > 0))
        ? (
          <>
            <List>
              <ListItem button component={Link} to="/" key="Dashboard">
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.light,
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white' }} primary="Dashboard" />
              </ListItem>
            </List>
            <Divider />
          </>
        ) : (
          <>
            <List>
              <ListItem button component={Link} to="/" key="login">
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.light,
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white' }} primary="Login" />
              </ListItem>
            </List>
            <Divider />
          </>
        )}
      {(process.env.REACT_APP_SITE_SUPPORTS_STORE === 'true')
        ? (
          <>
            <List>
              <ListItem button component={Link} to="/store" key="Store">
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.light,
                  }}
                >
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white' }} primary="Store" />
              </ListItem>
            </List>
            <Divider />
          </>
        )
        : (<></>)}
      {((user !== null) && (user.role === 'administrator'))
        ? (
          <>
            <List>
              <ListItem button component={Link} to="/management" key="Management">
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.light,
                  }}
                >
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white' }} primary="Management" />
              </ListItem>
            </List>
            <Divider />
          </>
        ) : (<></>)}
    </>
  );

  const userList = (anchor) => (
    <div
      style={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {links}
    </div>
  );
  const sideList = userList();

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(anchor, true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#484848',
              },
            }}
          >
            <div>
              {sideList}
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SideMenu;
