const graphQlFetch = (access, query) => (
  async function () {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const graphql = JSON.stringify({
      query,
      variables: {},
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/graphql`, requestOptions);
    const result = await response.json();
    return result;
  }
);

export default graphQlFetch;
