import {
  MANA_LOADING,
  MANA_LOAD_ERROR,
  GET_BUSINESSES,
  GET_BUSINESS,
  GET_BUSINESS_USERS,
  TOGGLE_BUSINESS_DIALOG,
} from './actions';

const initialState = {
  columns: [],
  business: [],
  businessUsers: [],
  businessEmployees: [],
  businessManagers: [],
  businesses: [],
  loading: false,
  loadError: false,
  businessDialogOpen: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const { type, columns, data } = action;
  switch (type) {
    case MANA_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case MANA_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_BUSINESSES:
      return {
        ...state,
        columns,
        businesses: data,
      };
    case GET_BUSINESS:
      return {
        ...state,
        business: data,
        businessEmployees: data.business_employees,
        businessManagers: data.business_managers,
      };
    case GET_BUSINESS_USERS:
      return {
        ...state,
        businessUsers: data,
      };
    case TOGGLE_BUSINESS_DIALOG:
      return {
        ...state,
        business: data,
        businessDialogOpen: !state.businessDialogOpen,
      };
    default:
      return state;
  }
}
