import { graphQlFetch } from '../../Utilities/Utilities';

export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const settingsLoadingAction = () => ({
  type: SETTINGS_LOADING,
});
export const SETTINGS_LOAD_ERROR = 'SETTINGS_LOAD_ERROR';
export const settingsLoadingErrorAction = (trigger, error) => ({
  type: SETTINGS_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const UPDATE_ACC = 'UPDATE_ACC';
export const updateAcc = (
  access,
  id,
  pass,
  username,
  mail,
  setMessageOn,
  setMessage,
  setStatus,
) => (
  async function () {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = { uid: id };
    if (pass) { body.pass = pass; }
    if (username) { body.name = username; }
    if (mail) { body.mail = mail; }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/service/user/reset?_format=json`, requestOptions);
    const result = await response.json();
    if (result.message) {
      setStatus('error');
      setMessage(result.message);
      setMessageOn(true);
    } else {
      setMessage('Your account has been updated');
      setMessageOn(true);
    }
    return {
      type: 'UPDATE_ACC',
    };
  }
);

export const SAVE_ALERTS = 'SAVE_ALERTS';
export const saveAlerts = (access, emailNotif, pushNotif) => (
  async function () {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = {};
    if (emailNotif) { body.field_email_alerts = emailNotif; }
    if (pushNotif) { body.field_push_alerts = pushNotif; }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/service/user/reset?_format=json`, requestOptions);
    const result = await response.json();
    if (result.message) {
      // setStatus('error');
      // setMessage(result.message);
      // setMessageOn(true);
    } else {
      // setMessage('Your account settings have been updated');
      // setMessageOn(true);
    }
    return {
      type: 'SAVE_ALERTS',
    };
  }
);

export const GET_ORDERS = 'GET_ORDERS';
export const getOrders = (access, format) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: SETTINGS_LOADING });
    const query = `{
      orders {
        items {
          id
          uuid
          invoice_id
          status
          delivered
          price
          sub_total
          tax_total
          shipping_charge
          shipping_fullname
          shipping_address_1
          shipping_address_2
          shipping_city
          shipping_state
          shipping_postal
          billing_fullname
          billing_address_1
          billing_address_2
          billing_city
          billing_state
          billing_postal
          created
          adapify_customer {
            id
            first_name
            last_name
            email
          }
          discount
          discountType
          productUnit
          product
          transaction
          kits
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.orders.items) {
      const orderArray = await result.data.orders.items;

      if (format === true) {
        orderArray.sort((a, b) => b.created - a.created);
        orderArray.forEach((e) => {
          const entry = e;
          entry.discount = JSON.parse(entry.discount);
          entry.discountType = JSON.parse(entry.discountType);
          entry.product = JSON.parse(entry.product);
          entry.productUnit = JSON.parse(entry.productUnit);
          entry.transaction = JSON.parse(entry.transaction);
          let name = '';
          if (entry.adapify_customer) {
            name = `${entry.adapify_customer[0].first_name} ${entry.adapify_customer[0].last_name}`;
          } else { name = 'Customer not Found'; }
          entry.adapify_customer[0].first_name = name;
          entry.kits = JSON.parse(entry.kits);
          // how do I determine total product count from purchase_order?

          // want to order return by most recent first
        });
      }
      return dispatch({
        type: 'GET_ORDERS',
        data: orderArray,
        columns: [
          { title: 'ID', field: 'id' },
          { title: 'Subtotal', field: 'sub_total' },
          { title: 'Name', field: 'adapify_customer[0].first_name' },
          { title: 'Email', field: 'adapify_customer[0].email' },
          { title: 'Invoice Id', field: 'invoice_id' },
        ],
      });
    }
    if (!result.data.orders.items) {
      const err = result;
      return dispatch({
        type: SETTINGS_LOAD_ERROR,
        data: err,
      });
    }
  }
);
