import { graphQlFetch, fileUpload } from '../../../Utilities/Utilities';

export const PROD_LOADING = 'PROD_LOADING';
export const productLoadingAction = () => ({
  type: PROD_LOADING,
});

export const PROD_LOAD_ERROR = 'PROD_LOAD_ERROR';
export const productLoadingErrorAction = (trigger, error) => ({
  type: PROD_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const getProducts = (access, fromRec = false) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const query = `{
      products {
        items {
          id
          uuid
          display_name
          description
          product_code
          quantity
          price
          weight
          size
          file_reference {
            id
            url
          }
          product_extension
          crop_ids
          type
          active
          prod_type
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    let productArray;
    if (await result.data) {
      productArray = await result.data.products.items;
      const returnArray = [];
      const pArr = productArray;
      pArr.forEach((product) => {
        if (fromRec) {
          if (product.type === 'rec' && (product.active === 1 || product.active === true)) {
            returnArray.push(product);
          }
        } else {
          returnArray.push(product);
        }
        const price = `${product.price}`;
        // eslint-disable-next-line no-param-reassign
        product.price = parseFloat(price).toFixed(2);
      });
      return dispatch({
        type: GET_PRODUCTS,
        data: returnArray,
        columns: [
          { title: 'ID', field: 'id' },
          { title: 'Name', field: 'display_name' },
          { title: 'Description', field: 'description' },
          { title: 'Type', field: 'type' },
          { title: 'Price', field: 'price' },
          { title: 'Weight', field: 'weight' },
        ],
      });
    }
    if (!result.data) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const TOGGLE_PRODUCT_DIALOG = 'TOGGLE_PRODUCT_DIALOG';
export const toggleProductDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }
    return dispatch({
      type: TOGGLE_PRODUCT_DIALOG,
      data: rowData,
    });
  }
);

export const GET_PRODUCT = 'GET_PRODUCT';
export const getProduct = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_store/product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const productArray = await JSON.parse(result.product);

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_PRODUCT',
        data: productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const EDIT_PROD = 'EDIT_PROD';
export const editProd = (access, product, file) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);
    let fid = null;
    if (file) {
      const fileReq = fileUpload(access, file);
      const fileResult = await fileReq();
      fid = fileResult.fid['0'].value;
    }
    const body = `{
      "id": "${product.uuid}",
      "display_name": "${product.display_name}",
      "description": "${product.description}",
      "product_code": "${product.product_code}",
      "quantity": "${product.quantity}",
      "price": "${product.price}",
      "weight": "${product.weight}",
      "size": "${product.size}",
      "file_reference": "${fid}",
      "product_extension": "{}",
      "active": "${product.active}",
      "type": "${product.type}",
      "prod_type": "${product.prod_type}"
    }`;
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_store/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      await dispatch(getProducts(access));
      return dispatch({
        type: 'EDIT_PROD',
        data: product.display_name,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_ARCHIVED_PRODUCTS = 'GET_ARCHIVED_PRODUCTS';
export const getArchivedProducts = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "archived",
      "partial": ''
    }`;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      return dispatch({
        type: 'GET_ARCHIVED_PRODUCTS',
        data: result.productArray,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_PROD = 'SAVE_PROD';
export const saveProd = (access, product, file) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);
    let fid = null;
    if (file) {
      const fileReq = fileUpload(access, file);
      const fileResult = await fileReq();
      fid = fileResult.fid[0].value;
    }
    const body = `{
      "id": "${product.uuid}",
      "display_name": "${product.display_name}",
      "description": "${product.description}",
      "product_code": "${product.product_code}",
      "quantity": "${product.quantity}",
      "price": "${product.price}",
      "weight": "${product.weight}",
      "size": "${product.size}",
      "file_reference": "${fid}",
      "product_extension": "{}",
      "active": "${product.active}",
      "type": "${product.type}",
      "prod_type": "${product.prod_type}"
    }`;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      await dispatch(getProducts(access));
      return dispatch({
        type: 'SAVE_PROD',
        data: product.display_name,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const getAllOrders = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "all",
      "filter": "none"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_order_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const orderArray = await result.orderArray;

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_ALL_ORDERS',
        data: orderArray,
        orderColumns: [ //
          { title: 'ID', field: 'id' },
          { title: 'Status', field: 'status' },
          { title: 'Date', field: 'date' },
          { title: 'Subtotal', field: 'sub_total' },
          { title: 'Name', field: 'customer_name' },
          { title: 'Email', field: 'customer_email' },
          { title: 'Invoice Id', field: 'invoice_id' },
        ],
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS';
export const getCustomerOrders = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "all",
      "filter": "customer"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_order_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const orderArray = await result.orderArray;

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_CUSTOMER_ORDERS',
        data: orderArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const setOrderStatus = (access, orderId, status) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "orderId": "${orderId}",
      "status": "${status}"
    }`;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      await dispatch(getAllOrders(access));
      return dispatch({
        type: 'SET_ORDER_STATUS',
        data: orderId,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// function to set orders between timeframe to shipped
export const SET_ORDERS_SHIPPED = 'SET_ORDERS_SHIPPED';
export const setOrdersShipped = (access, start, end) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "start": "${start}",
      "end": "${end}"
    }`;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      await dispatch(getAllOrders(access));
      return dispatch({
        type: 'SET_ORDERS_SHIPPED',
        data: result.status,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// function to pull merged pdfs
export const GET_MERGED_PDF = 'GET_MERGED_PDF';
export const getMergedPdf = (
  access,
  start,
  end,
  type,
  handlePrintKitLabelsButton,
  handleShippingLabels,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: PROD_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "start": "${start}",
      "end": "${end}",
      "type": "${type}",
      "get": "PDF"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200 && type === 'kit') {
      // currently maintaining limit from the shipping pdf generation
      handlePrintKitLabelsButton(result.kits);
      return dispatch({
        type: 'GET_MERGED_PDF',
        data: result.status,
      });
    }
    if (result.status === 200 && (type === 'lab' || type === 'customer')) {
      // call function from frontend that loops through all files returned
      handleShippingLabels(result.pdfs, type);
      return dispatch({
        type: 'GET_MERGED_PDF',
        data: result.pdfs,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: PROD_LOAD_ERROR,
        data: err,
      });
    }
  }
);
