import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import MaterialTable from '@material-table/core';
// import MaterialTable from '@material-table/core';
// import { EditIcon } from '@mui/icons-material/Edit';
import { MaterialReactTable } from 'material-react-table';
import {
  Card, CardMedia, Chip,
  Button, Typography, Dialog,
  DialogTitle, DialogContent, DialogActions,
  FormControl, Select, MenuItem, Box,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useRefreshToken } from 'react-auth-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { setOrderStatus } from './Management/Store/redux/actions';
import { retrieveShippingPDF } from './RxSoil/redux/actions';
import { RefreshComponent } from './Utilities/Utilities';
import theme from './theme';

const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const kitCopyNumber = 4;

const root = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};
const topRoot = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '1%',
};
const bottomRoot = {
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down(1100)]: {
    flexDirection: 'column',
  },
};
const leftRoot = {
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  height: 'auto',
  margin: '11px 0px 10px 17px',
  [theme.breakpoints.down(1100)]: {
    width: '97%',
  },
  [theme.breakpoints.down(883)]: {
    margin: '11px 0px 10px 6px',
  },
};
const rightRoot = {
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
  padding: '15px 28px 10px',
  maxWidth: 481,
  [theme.breakpoints.down(1100)]: {
    justifyContent: 'center',
    marginLeft: 20,
    flexDirection: 'row',
    width: '100%',
    minWidth: '93%',
  },
  [theme.breakpoints.down(883)]: {
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 0,
    maxWidth: '96%',
    padding: '0px',
  },
};
const totalBox = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'whitesmoke',
  maxWidth: 481,
  margin: '0px 0px 10px',
  minHeight: 54,
  [theme.breakpoints.down(1100)]: {
    display: 'none',
  },
};
const headings = {
  fontWeight: 500,
  padding: '21px 16px 13px',
  fontSize: 19,
};
const itemHead = {
  fontWeight: 500,
  padding: '21px 16px 13px',
  fontSize: 19,
  [theme.breakpoints.down(634)]: {
    padding: '13px 16px 13px',
  },
};
const itemBox = {
  display: 'flex',
  marginBottom: 10,
  marginTop: '3%',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '99%',
};
const itemPicAndTitle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
const rightCards = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'whitesmoke',
  maxWidth: 481,
  margin: '0px 0px 3%',
  minHeight: 54,
  [theme.breakpoints.down(1100)]: {
    minWidth: '50%',
    marginRight: 20,
    maxWidth: '100%',
  },
  [theme.breakpoints.down(883)]: {
    width: '93%',
    marginRight: 0,
  },
};
const chipBox = {
  position: 'absolute',
  right: 54,
  [theme.breakpoints.down(1100)]: {
    right: 0,
    left: '36%',
  },
  [theme.breakpoints.down(882)]: {
    right: 0,
    left: '81%',
  },
  [theme.breakpoints.down(782)]: {
    right: 0,
    left: '76.5%',
  },
  [theme.breakpoints.down(583)]: {
    right: 0,
    left: '63%',
  },
};
const chip = {
  color: 'white',
  backgroundColor: '#8cc63e',
};
const subHeading = {
  color: 'grey',
  padding: '4px 18px',
  fontSize: 16,
};
const summContent = {
  fontWeight: 500,
  fontSize: 17,
  marginRight: 16,
};
const address = {
  fontWeight: 500,
  paddingLeft: 19,
};
const cardMedia = {
  margin: '10px 17px 10px',
};
const mediaImg = {
  maxHeight: 67,
};
const priceBox = {
  display: 'flex',
  margin: '0px 49px 0px',
};
const itemsSummBox = {
  display: 'flex',
  height: 'auto',
  backgroundColor: 'whitesmoke',
  flexDirection: 'column',
  margin: 6,
  overflow: 'visible',
  [theme.breakpoints.down(1100)]: {
    overflow: 'visible',
  },
  [theme.breakpoints.down(634)]: {
    overflow: 'scroll',
    maxWidth: '95%',
    margin: '2%',
  },
};
const shipmentCard = {
  display: 'flex',
  flexDirection: 'column',
  height: '60%',
  overflow: 'visible',
  margin: '6px',
  marginTop: '26px',
  marginBottom: '3%',
  [theme.breakpoints.down(634)]: {
    overflow: 'scroll',
  },
};
const giftCard = {
  display: 'flex',
  flexDirection: 'column',
  height: '60%',
  overflow: 'visible',
  margin: 6,
  marginTop: 26,
  marginBottom: 0,
  [theme.breakpoints.down(634)]: {
    overflow: 'scroll',
  },
};
const giftInfo = {
  fontSize: 16,
  padding: 10,
};
const itemsHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottomStyle: 'solid',
  borderWidth: 1,
  borderColor: 'lightgrey',
};
const itemHeading = {
  color: 'grey',
  fontSize: 16,
  margin: '13px 28px 0px',
  minWidth: 83,
  [theme.breakpoints.down(634)]: {
    margin: '1px 0px 0px 58px',
  },
};
const transactionID = {
  fontSize: 20,
  color: '#8cc63e',
  fontWeight: 500,
  marginLeft: 5,
  [theme.breakpoints.down(634)]: {
    fontSize: 16,
    marginLeft: '0px',
  },
};

const OrderSummary = (props) => {
  const dispatch = useDispatch();
  const { order, adminStatus } = props;
  if (!useRefreshToken().getCurrentAuthState().authToken) { RefreshComponent(); }
  const access = useRefreshToken().getCurrentAuthState().authToken;
  let myStatus = order.status;
  if (myStatus) {
    myStatus = 'payment_confirmed';
  }
  const [thisOrderStatus, setThisOrderStatus] = useState('payment_confirmed');
  const [cancelOpen, setCancelOpen] = useState(false);
  const [shipOpen, setShipOpen] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [shipped, setShipped] = useState(false);
  const [shippedClose, setShippedClose] = useState(false);
  const [cancelledClose, setCancelledClose] = useState(false);
  const [shippingPDFName, setShippingPDFName] = useState('');
  const shippingPDFData = useSelector((state) => state.rxsoil.pdfData);
  const [status, setStatus] = useState('payment_confirmed');

  const kitColumns = useMemo(
    () => [
      {
        header: 'Soil Kit Id',
        accessorKey: 'soil_kit_id',
      },
      {
        header: 'SSF ID',
        accessorKey: 'ssf_id',
      },
      {
        header: 'Roles',
        accessorKey: 'roles',
      },
    ],
    [],
  );

  const handleChange = (event) => {
    // eslint-disable-next-line no-param-reassign
    if (status !== 'cancelled' && status !== 'shipped') {
      // eslint-disable-next-line no-param-reassign
      const orderS = event.target.value;
      if (event.target.value === 'cancelled') {
        setCancelOpen(true);
      } else if (event.target.value === 'shipped') {
        setShipOpen(true);
      }
      setThisOrderStatus(orderS);
    } else {
      // Could add adtnl behaviour letting them know to change this they must contact an admin
      // they were warned before selecting ship or cancel they would not be allowed to change
    }
  };

  const handleCancelClose = () => {
    setThisOrderStatus('payment_confirmed');
    setCancelOpen(false);
  };

  const handleCancelConfirm = (order) => {
    dispatch(setOrderStatus(access, order.id, thisOrderStatus));
    setStatus('cancelled');
    // eslint-disable-next-line no-param-reassign
    order.status = 'cancelled';
    setCancelOpen(false);
    setCancelled(true);
    setCancelledClose(false);
  };

  const handleShipClose = () => {
    setThisOrderStatus('payment_confirmed');
    setShipOpen(false);
  };

  const handleShippedClose = () => {
    setShippedClose(false);
  };

  const handleCancelledClose = () => {
    setCancelledClose(false);
  };

  const handleShipConfirm = (order) => {
    dispatch(setOrderStatus(access, order.id, thisOrderStatus));
    setStatus('shipped');
    // eslint-disable-next-line no-param-reassign
    order.status = 'shipped';
    setShipOpen(false);
    setShipped(true);
    setShippedClose(false);
  };

  const handleShippingButton = async (isKit, soilKitId, direction) => {
    if (isKit && soilKitId) {
      setShippingPDFName(`kit-${direction}-${soilKitId}-shipping-label.pdf`);
      await dispatch(retrieveShippingPDF(access, 'kit', soilKitId, direction));
    }
  };

  // this handler listens for lab labels printing from single sits & batches:
  // params only supplied for single kits
  const handlePrintKitLabelsButton = async (soilKitId, ssfId, type, orderRowData) => {
    let kitToEvaluate = [];
    const kitsCreated = [];
    const content = [];
    let count = 0;
    let pdfTitle = '';
    let typeName = '';
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) { dd = `0${dd}`; }
    if (mm < 10) { mm = `0${mm}`; }
    const todayString = `${mm}/${dd}/${yyyy}`;

    if (!orderRowData) {
      // have to check crop_id here to set values
      if (type === 'lawn') {
        typeName = 'Lwn';
      } else if (type === 'garden') {
        typeName = 'Grd';
      } else if (type === 'landscape') {
        typeName = 'Lnd';
      } else if (type === 'plfa') {
        typeName = 'PLFA';
      } else if (type === 'lead') {
        typeName = 'LEAD';
      } else if (type === 'rcra') {
        typeName = 'RCRA';
      } else if (type === 'glyphosate') {
        typeName = 'GLYPHOSATE';
      } else if (type === 'phenoxy_chlorinated') {
        typeName = 'PHENOXY_CHLOR';
      } else if (type === 'chlorinated') {
        typeName = 'CHLOR';
      } else if (type === 'herbicide') {
        typeName = 'HERBICIDE';
      } else if (type === 'insecticide') {
        typeName = 'INSECTICIDE';
      } else if (type === 'fungicide') {
        typeName = 'FUNGICIDE';
      } else if (type === 'herb_glyph') {
        typeName = 'HERB_GLYPH';
      } else if (type === 'texture') {
        typeName = 'TEXTURE';
      } else if (type === 'compost') {
        typeName = 'COMPOST';
      } else if (type === 'water_basic') {
        typeName = 'WATER_BASIC';
      } else if (type === 'water_bacteria') {
        typeName = 'WATER_BACTERIA';
      } else if (type === 'water_basic_bacteria') {
        typeName = 'WATER_BASIC_BACTERIA';
      } else if (type === 'water_irrigation') {
        typeName = 'WATER_IRRIGATION';
      } else if (type === 'water_irrigation_bacteria') {
        typeName = 'WATER_IRRIGATION_BACTERIA';
      } else if (type === 'water_epa') {
        typeName = 'WATER_EPA';
      } else if (type && type.startsWith('water_metal_')) {
        typeName = 'WATER_METAL';
      } else if (type === 'water_pest_preemerge') {
        typeName = 'WATER_PEST_PREEMERGE';
      } else if (type === 'water_pest_phenoxy') {
        typeName = 'WATER_PEST_PHENOXY';
      } else if (type === 'water_pest_chlorinated') {
        typeName = 'WATER_PEST_CHLORINATED';
      } else if (type === 'water_pest_organophosphate') {
        typeName = 'WATER_PEST_ORGANO';
      } else if (type === 'water_pest_pyrethroid') {
        typeName = 'WATER_PEST_PYRETHROID';
      } else if (type === 'water_pest_sulfonylurea') {
        typeName = 'WATER_PEST_SULFON';
      } else if (type === 'water_pest_glyphosate') {
        typeName = 'WATER_PEST_GLYPH';
      }
      kitToEvaluate.push({
        soil_kit_id: soilKitId,
        ssf_id: ssfId,
      });
    } else {
      kitToEvaluate = orderRowData;
    }
    pdfTitle = `kit-lab-${soilKitId}.pdf`;

    for (count = 0; count < kitToEvaluate.length; count += 1) {
    // kits.forEach(index, value); { //think of as foreach key value
      kitsCreated[count] = [];
      kitsCreated[count].kit = kitToEvaluate[count].soil_kit_id;
      kitsCreated[count].ssf = kitToEvaluate[count].ssf_id;
      if (orderRowData) {
        if (orderRowData[count].type === 2 || orderRowData[count].type === 3) {
          typeName = 'Lwn';
        } else if (orderRowData[count].type === 4
          || orderRowData[count].type === 5
          || orderRowData[count].type === 6) {
          typeName = 'Grd';
        } else if (orderRowData[count].type === 7) {
          typeName = 'Lnd';
        }
      }
      for (let kitCopyCounter = 0; kitCopyCounter < kitCopyNumber; kitCopyCounter += 1) {
        if (typeName === 'PLFA') {
          content.push({
            text: ['PLFA'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ['Bill To Account: 80531'],
            style: 'labelText',
            alignment: 'center',
          },
          {
            text: [`Grower: ${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
          },
          {
            text: [`Date: ${todayString}`],
            style: 'labelText',
            alignment: 'center',
          });
        } else if (typeName === 'LEAD') {
          content.push({
            text: ['Cust No: 23834'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ['Metals Testing'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: ['Lead-Only'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`PO: ${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          });
        } else if (typeName === 'RCRA') {
          content.push({
            text: ['Cust No: 23834'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ['Metals Testing'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: ['As,Ba,Cd,Cr,Pb,Hg,Se,Ag'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`PO: ${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          });
        } else if (typeName === 'TEXTURE') {
          content.push({
            text: ['Texture Sample #:'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: [`${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 17,
            bold: true,
            margin: [0, 0, 0, 4],
          },
          {
            text: ['Lab #:'],
            style: 'labelText',
            alignment: 'center',
          },
          {
            text: [kitsCreated[count].ssf],
            style: 'labelText',
            alignment: 'center',
          });
        } else if (typeName === 'COMPOST') {
          content.push({
            text: ['Compost Sample #:'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: [`${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 17,
            bold: true,
            margin: [0, 0, 0, 4],
          },
          {
            text: ['Lab #:'],
            style: 'labelText',
            alignment: 'center',
          },
          {
            text: [kitsCreated[count].ssf],
            style: 'labelText',
            alignment: 'center',
          });
        } else if (typeName === 'HERBICIDE' || typeName === 'FUNGICIDE' || typeName === 'INSECTICIDE' || typeName === 'GLYPHOSATE' || typeName === 'HERB_GLYPH' || typeName === 'PHENOXY_CHLOR' || typeName === 'CHLOR') {
          let serviceLabel = '';
          if (typeName === 'HERBICIDE') { serviceLabel = 'PhenoxyHerb'; } else if (typeName === 'FUNGICIDE') { serviceLabel = 'Fungicide Screen'; } else if (typeName === 'INSECTICIDE') { serviceLabel = 'Pyrethroid+Imidacloprid'; } else if (typeName === 'GLYPHOSATE') { serviceLabel = 'Glyphosate'; } else if (typeName === 'HERB_GLYPH') { serviceLabel = 'PhenoxyHerb,Glyphosate'; } else if (typeName === 'PHENOXY_CHLOR') { serviceLabel = 'Phenoxy/Chlorinated'; } else if (typeName === 'CHLOR') { serviceLabel = 'Chlorinated Screen'; }

          content.push({
            text: ['Cust No: 23834'],
            style: 'labelText',
            alignment: 'center',
            color: 'red',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ['Pesticide Testing'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`${serviceLabel}`],
            style: 'labelText',
            fontSize: 13,
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`PO: ${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          });
        } else if (typeName === 'WATER_BASIC' || typeName === 'WATER_BACTERIA' || typeName === 'WATER_BASIC_BACTERIA' || typeName === 'WATER_IRRIGATION' || typeName === 'WATER_IRRIGATION_BACTERIA' || typeName === 'WATER_EPA' || typeName === 'WATER_METAL' || typeName === 'WATER_PEST_PREEMERGE' || typeName === 'WATER_PEST_PHENOXY' || typeName === 'WATER_PEST_CHLORINATED' || typeName === 'WATER_PEST_ORGANO' || typeName === 'WATER_PEST_PYRETHROID' || typeName === 'WATER_PEST_SULFON' || typeName === 'WATER_PEST_GLYPH') {
          let serviceLabel = '';
          if (typeName === 'WATER_BASIC') {
            serviceLabel = 'Domestic - No Bact';
          } else if (typeName === 'WATER_BACTERIA') {
            serviceLabel = 'Bact Only';
          } else if (typeName === 'WATER_BASIC_BACTERIA') {
            serviceLabel = 'Domestic';
          } else if (typeName === 'WATER_IRRIGATION') {
            serviceLabel = 'Irrigation';
          } else if (typeName === 'WATER_IRRIGATION_BACTERIA') {
            serviceLabel = 'Irrigation + Bact';
          } else if (typeName === 'WATER_EPA') {
            serviceLabel = 'Metals Package';
          } else if (typeName === 'WATER_METAL') {
            const metalPart = type.split('_').pop();
            const metalName = metalPart.charAt(0).toUpperCase() + metalPart.slice(1).toLowerCase();
            serviceLabel = `${metalName} Only`;
          } else if (typeName === 'WATER_PEST_PREEMERGE') {
            serviceLabel = 'Herbicide Screen';
          } else if (typeName === 'WATER_PEST_PHENOXY') {
            serviceLabel = 'Phenoxy Screen';
          } else if (typeName === 'WATER_PEST_CHLORINATED') {
            serviceLabel = 'Chlorinated Screen';
          } else if (typeName === 'WATER_PEST_ORGANO') {
            serviceLabel = 'Organophos Screen';
          } else if (typeName === 'WATER_PEST_PYRETHROID') {
            serviceLabel = 'Pyrethroid Screen';
          } else if (typeName === 'WATER_PEST_SULFON') {
            serviceLabel = 'Sulfonylurea Screen';
          } else if (typeName === 'WATER_PEST_GLYPH') {
            serviceLabel = 'Glyphosate Screen';
          }
          content.push({
            text: ['Cust No: 23834'],
            style: 'labelText',
            alignment: 'center',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: [`${serviceLabel}`],
            style: 'labelText',
            alignment: 'center',
            color: '#f53e31',
            fontSize: 16,
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            text: ['Sample ID:'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            color: '#f53e31',
            margin: [0, 3, 0, 0],
          });
        } else {
          content.push({
            text: ['Cust No: 23834'],
            style: 'labelText',
            alignment: 'center',
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ['Nutrient Testing'],
            style: 'labelText',
            alignment: 'center',
            color: '#f53e31',
            fontSize: 16,
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            text: ['Sample ID:'],
            style: 'labelText',
            alignment: 'center',
            margin: [0, 3, 0, 0],
          },
          {
            text: [`${kitsCreated[count].kit}`],
            style: 'labelText',
            alignment: 'center',
            color: '#f53e31',
            margin: [0, 3, 0, 0],
          });
        }
      } // end foreach copies of same label
    // content.push({text: 'Text is lastest on the page', pageBreak: 'after'});
    // pagebreak syntax... not needed?
    }
    // end foreach kit to print in request collection
    // document object for pdf generation
    const docDefinition = {
      pageSize: { width: 2.0 * 100, height: 1.0 * 100 },
      pageMargins: [0, 12, 0, 11],
      content,
      styles: {
        labelText: {
          fontSize: 14,
          bold: true,
          alignment: 'justify',
        },
      },
    };
    pdfMake.createPdf(docDefinition).download(pdfTitle);// .print();, .open();
  };

  const generateKits = (thisOrder) => {
    const kitTable = [];

    if (thisOrder.kits) {
      const printActions = true;
      kitTable.push(
        <>
          <div style={{ display: printActions ? 'block' : 'none' }}>
            <br />
            {/* <MaterialTable
              title="Kits Ordered"
              columns={[
                { title: 'Soil Kit ID', field: 'soil_kit_id' },
                { title: 'SSF ID', field: 'ssf_id' },
                {
                  title: 'Crop Id',
                  field: 'crop_id',
                  cellStyle: {
                    display: 'none',
                  },
                  headerStyle: {
                    display: 'none',
                  },
                },
              ]}
              data={thisOrder.kits}
              options={{ paging: false, search: false, actionsColumnIndex: 2 }}
              localization={{ header: { actions: 'Printing' } }}
              cellStyle={{ width: 10, maxWidth: 10 }}
              actions={[
                {
                  icon: 'science',
                  tooltip: 'Generate Lab Label',
                  onClick: (event, orderRowData) => {
                    handlePrintKitLabelsButton(
                      orderRowData.soil_kit_id,
                      orderRowData.ssf_id,
                      orderRowData.product_type,
                    );
                  },
                },
                {
                  icon: 'markunread_mailbox',
                  tooltip: 'Generate Customer Shipping Label',
                  onClick: (event, orderRowData) => {
                    handleShippingButton(true, orderRowData.soil_kit_id, 'customer');
                  },
                },
                {
                  icon: 'local_shipping',
                  tooltip: 'Generate Lab Shipping Label',
                  onClick: (event, orderRowData) => {
                    handleShippingButton(true, orderRowData.soil_kit_id, 'lab');
                  },
                },
              ]}
            /> */}
            <Typography variant="h4">
              Kits Ordered
            </Typography>
            <MaterialReactTable
              columns={kitColumns}
              data={thisOrder.kits}
              enableRowActions
              renderRowActionMenuItems={({ row }) => [
                <MenuItem
                  key="generate lab label"
                  onClick={() => {
                    handlePrintKitLabelsButton(
                      row.original.soil_kit_id,
                      row.original.ssf_id,
                      row.original.product_type,
                    );
                  }}
                >
                  Generate Lab Label
                </MenuItem>,
                <MenuItem
                  key="generate customer shipping label"
                  onClick={() => {
                    handleShippingButton(true, row.original.soil_kit_id, 'customer');
                  }}
                >
                  Generate Customer Shipping Label
                </MenuItem>,
                <MenuItem
                  key="edit"
                  onClick={() => {
                    handleShippingButton(true, row.original.soil_kit_id, 'lab');
                  }}
                >
                  Generate Lab Shipping Label
                </MenuItem>,
              ]}
              positionActionsColumn="last"
            />
          </div>
        </>,
      );
    } else { kitTable.push(<></>); }
    return kitTable;
  };

  const getOrderStatus = (thisOrder) => {
    const chips = []; // Renamed variable for array
    let custOrderStatus = '';
    if (thisOrder.status) {
      if (thisOrder.status === 'purchase_confirmed' || thisOrder.status === 'charge_completed') {
        custOrderStatus = 'Processing';
      } else if (thisOrder.status === 'cancelled') {
        custOrderStatus = 'Cancelled';
      } else if (thisOrder.status === 'shipped') {
        custOrderStatus = 'Shipped';
      }
      chips.push(
        <div style={chipBox}>
          <Chip sx={chip} label={custOrderStatus} />
        </div>,
      );
    }
    return chips;
  };

  // thisOrder is the loaded order coming from props, need to reassign on change
  const giveDropDownMenu = (thisOrder) => {
    const menu = [];
    let orderStatus = '';

    // any time this runs it is checking what the order was when it was initially loaded ThisOrder
    if (thisOrder.status) {
      if (thisOrder.status === 'purchase_confirmed' || thisOrder.status === 'charge_completed') {
        orderStatus = 'payment_confirmed';
        if (status !== 'payment_confirmed') {
          setStatus('payment_confirmed');
        }
      } else if (thisOrder.status === 'cancelled') {
        orderStatus = 'cancelled';
        if (status !== 'cancelled') {
          setStatus('cancelled');
        }
      } else if (thisOrder.status === 'shipped') {
        orderStatus = 'shipped';
        if (status !== 'shipped') {
          setStatus('shipped');
        }
      }

      if (orderStatus === 'payment_confirmed') {
        menu.push(
          <FormControl style={{ width: '68%', alignSelf: 'center' }}>
            <Select
              style={{ width: '100%' }}
              id="status-select"
              value={status}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="payment_confirmed">Payment Confirmed</MenuItem>
              <MenuItem value="shipped">Shipped</MenuItem>
              <MenuItem value="cancelled">Cancel Order</MenuItem>
            </Select>
          </FormControl>,
        );
      } else {
        menu.push(
          <>{orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1)}</>,
        );
      }
    }
    return menu;
  };

  const getAllProductIDs = (prodUnits) => {
    const allProductIDs = []; // array for all product_id's
    if (prodUnits) {
      // eslint-disable-next-line array-callback-return
      prodUnits.map((prodUnit) => {
        allProductIDs.push(prodUnit.product_id);
      });
    }

    return allProductIDs;
  };

  const giveTransactionData = (thisOrder) => {
    const transactionData = [];

    transactionData.push(
      <div style={{
        marginLeft: '3.4%', marginTop: '2%', marginRight: '5%', marginBottom: '1%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'left',
        }}
        >
          <Typography style={{ fontSize: 19, fontWeight: 500 }}>
            Confirmation ID -
          </Typography>
          <Typography
            sx={transactionID}
          >
            {thisOrder.transaction.confirmation_id}
          </Typography>
        </div>
      </div>,
    );

    return transactionData;
  };

  const getDate = (thisOrder) => {
    const dateData = [];
    let newDate = 'Date Not Found';
    if (thisOrder.transaction) {
      const theDate = new Date(thisOrder.transaction.status_date * 1000).toLocaleDateString('en-US');
      newDate = theDate;
    }
    if (thisOrder.transaction) {
      dateData.push(
        <Typography sx={summContent}>
          {newDate}
        </Typography>,
      );
    }

    return dateData;
  };

  const getCardData = (thisOrder) => {
    const cardData = [];

    if (thisOrder.transaction.cc_last_digits) {
      cardData.push(
        <Typography style={{ paddingLeft: 9 }}>
          Card Ending in
          {order.transaction.cc_last_digits}
        </Typography>,
      );
    } else {
      cardData.push(
        <Typography style={{ paddingLeft: 9 }}>
          No Card Data in Database
        </Typography>,
      );
    }

    return cardData;
  };

  const giveProductView = (order) => {
    const prodArr = [];
    let name = '';
    const allProductIDs = getAllProductIDs(order.productUnit);
    let totalPrice = 0;
    let filePath = order.product[0].file_path;
    if (order.product[0].display_name.length > 30) {
      name = `${order.product[0].display_name.substr(0, 28)}...`;
    } else {
      name = order.product[0].display_name;
    }
    // eslint-disable-next-line array-callback-return
    order.productUnit.map((product, i) => {
      totalPrice = product.price * product.quantity;
      const firstOccurenceOfProdID = allProductIDs.indexOf(product.product_id);

      if (order.product[0].id !== product.product_id) {
        // eslint-disable-next-line array-callback-return
        order.product.map((productArrItem, i) => {
          if (productArrItem.id === product.product_id) {
            filePath = order.product[i].file_path;
            if (order.product[i].display_name.length > 30) {
              name = `${order.product[i].display_name.substr(0, 28)}...`;
            } else {
              name = order.product[i].display_name;
            }
          }
        });
      }

      if (i === firstOccurenceOfProdID) {
        prodArr.push(
          <div style={itemBox}>
            <div style={itemPicAndTitle}>
              <CardMedia sx={cardMedia}>
                <img style={mediaImg} src={`${process.env.REACT_APP_ADAPIFY_SITE_URL}${filePath}`} alt="product" />
              </CardMedia>
              <div style={{ display: 'flex', flexDirection: 'column', minWidth: 121 }}>
                <Typography style={{ fontWeight: 500 }}>
                  {name}
                </Typography>
                <Typography style={{ color: 'grey' }}>
                  QTY:
                  {product.quantity}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography sx={priceBox}>
                {Number(product.price).toFixed(2)}
              </Typography>
              <Typography sx={priceBox}>
                {Number(totalPrice).toFixed(2)}
              </Typography>
            </div>
          </div>,
        );
      }
    });

    return prodArr;
  };

  useEffect(() => {
    if (shippingPDFData && shippingPDFName && !shippingPDFData.name) {
      // var bin = atob(shippingPDFData);

      const a = document.createElement('a');
      a.innerHTML = 'Download PDF file';
      a.download = shippingPDFName;
      a.href = `data:application/octet-stream;base64,${shippingPDFData}`;

      try {
        a.dispatchEvent(new MouseEvent('click'));
      } catch (e) {
        const evt = document.createEvent('MouseEvents');
        evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
          20, false, false, false, false, 0, null);
        a.dispatchEvent(evt);
      }
      dispatch(retrieveShippingPDF(access, 'rerender', '', ''));
    }
  }, [shippingPDFData, cancelled, shipped, status, shipOpen, cancelOpen, order]);

  return (
    <div style={root}>
      <div style={topRoot}>
        <Typography style={{ fontSize: 30, marginLeft: '3%', marginTop: '2%' }}>
          Invoice ID-
          {order.invoice_id}
        </Typography>
      </div>
      <Box sx={bottomRoot}>
        <Box sx={leftRoot}>
          <Card
            sx={itemsSummBox}
            variant="raised"
          >
            <div
              style={itemsHeader}
            >
              <Typography sx={itemHead} style={{ minWidth: 194 }}>
                Items Summary
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={itemHeading}>
                  Item Price
                </Typography>
                <Typography sx={itemHeading}>
                  Total Price
                </Typography>
              </div>
            </div>
            {order.productUnit && giveProductView(order)}
          </Card>
          {(order.order_is_gift === '1' && order.gift_order_message) ? (
            <Card
              variant="raised"
              sx={giftCard}
            >
              <div style={{
                borderBottomStyle: 'solid',
                borderWidth: 1,
                borderColor: 'lightgrey',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              >
                <Typography sx={headings} style={{ marginLeft: -5 }}>
                  Gift Order Info
                </Typography>
              </div>

              <div style={giftInfo}>
                Recipient Name:&nbsp;
                {order.recipient_fullname}
              </div>
              <div style={giftInfo}>
                Order Message:&nbsp;
                {order.gift_order_message}
              </div>

            </Card>
          ) : (
            <></>
          )}
          {(order.order_is_gift === '1' && !order.gift_order_message) ? (
            <Card
              variant="raised"
              sx={giftCard}
            >
              <div style={{
                borderBottomStyle: 'solid',
                borderWidth: 1,
                borderColor: 'lightgrey',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              >
                <Typography sx={headings} style={{ marginLeft: -5 }}>
                  Gift Order Info
                </Typography>
              </div>

              <div style={giftInfo}>
                Recipient Name:&nbsp;
                {order.recipient_fullname}
              </div>

            </Card>
          ) : (
            <></>
          )}
          <Card
            variant="raised"
            sx={shipmentCard}
          >
            <div style={{
              borderBottomStyle: 'solid',
              borderWidth: 1,
              borderColor: 'lightgrey',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <LocalShippingIcon style={{ marginLeft: '11px', marginTop: '8px' }} />
              <Typography sx={headings} style={{ marginLeft: -5 }}>
                Shipments
              </Typography>
            </div>
            {order.transaction && giveTransactionData(order)}
            {adminStatus && giveDropDownMenu(order)}
            <Card style={{
              backgroundColor: 'whitesmoke', marginTop: 7, display: 'flex', marginBottom: '3%', flexDirection: 'column', alignSelf: 'center', width: '90%', minHeight: '10vh',
            }}
            >
              {order.productUnit && giveProductView(order)}
            </Card>
            {adminStatus && generateKits(order)}
          </Card>
        </Box>
        <Box sx={rightRoot}>
          <Card sx={rightCards} style={{ paddingBottom: 14 }} variant="raised">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={headings}>
                Summary
              </Typography>
              {(adminStatus === false) && getOrderStatus(order)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={subHeading}>
                Order Created
              </Typography>
              {getDate(order)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={subHeading}>
                Subtotal
              </Typography>
              <Typography sx={summContent}>
                $
                {Number(order.sub_total).toFixed(2)}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={subHeading}>
                Shipping Fee
              </Typography>
              <Typography sx={summContent}>
                $
                {Number(order.shipping_charge).toFixed(2)}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={subHeading}>
                Taxes
              </Typography>
              <Typography sx={summContent}>
                $
                {Number(order.tax_total).toFixed(2)}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={subHeading}>
                Discount
              </Typography>
              <Typography sx={summContent}>
                $
                {
                  ((Number(order.tax_total)
                  + Number(order.sub_total)
                  + Number(order.shipping_charge))
                  - Number(order.price)).toFixed(2)
                }
              </Typography>
            </div>
          </Card>
          <Card sx={totalBox} variant="raised">
            <Typography sx={subHeading}>
              Total
            </Typography>
            <Typography sx={summContent}>
              $
              {Number(order.price).toFixed(2)}
            </Typography>
          </Card>
          <Card sx={rightCards} style={{ paddingBottom: 16 }} variant="raised">
            <Typography sx={headings}>
              Order Details
            </Typography>
            <Typography sx={subHeading}>
              Shipping Address
            </Typography>
            <Typography sx={address}>
              {order.shipping_address_1}
            </Typography>
            {order.shipping_address_2 && (
            <Typography sx={address}>
              {order.shipping_address_2}
            </Typography>
            )}
            <Typography sx={address}>
              US
            </Typography>
            <div
              style={{
                padding: '4px 18px', fontSize: 16, color: 'black', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={faCreditCard} />
              {order.transaction && getCardData(order)}
            </div>
          </Card>
        </Box>
      </Box>
      <Dialog
        open={cancelOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          Are you sure you want to cancel this order? This action cannot be undone .
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            Close
          </Button>
          <Button onClick={() => handleCancelConfirm(order)} color="primary">
            Cancel Order
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={shipOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleShipClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Ship Order</DialogTitle>
        <DialogContent>
          Are you sure you want to mark this order as shipped? This action cannot be undone
          and will send a confirmation email to the customer.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShipClose} color="primary">
            Close
          </Button>
          <Button onClick={() => handleShipConfirm(order)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={shippedClose}
        fullWidth
        maxWidth="sm"
        onClose={handleShippedClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Order Shipped</DialogTitle>
        <DialogContent>
          {`Order ${order.id} has been marked as shipped.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShippedClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cancelledClose}
        fullWidth
        maxWidth="sm"
        onClose={handleCancelledClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Order Cancelled</DialogTitle>
        <DialogContent>
          {`Order ${order.id} has been cancelled.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelledClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderSummary;
