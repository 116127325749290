// React Redux Core
import React from 'react';
// MUI and other Libraries
import {
  FormControl,
  InputLabel,
  Input,
} from '@mui/material';
// Adapify

export const fileUpload = (access, file) => (
  async function () {
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const fileName = `${timestamp}-${file.name}`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/octet-stream');
    myHeaders.append('Content-Disposition', `file; filename="${fileName}";`);
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/file/upload/adapify_product/adapify_product/file_reference`, requestOptions);
    const result = await response.json();

    return result;
  }
);

const FileUpload = (props) => {
  // const classes = useStyles();
  const { onChange, fileRef } = props;

  const handleInput = (e) => {
    const file = e.target.files[0];
    const preview = document.querySelector('img#thumb');
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // convert image file to base64 string
      preview.src = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
    onChange(file);
  };
  let url;
  if (!fileRef) {
    url = `${process.env.REACT_APP_ADAPIFY_SITE_URL}/sites/default/files/adapify/product_image_unavailable.jpg`;
  } else {
    url = fileRef.url;
  }
  const field = {
    marginLeft: 10,
    width: 'calc(100% - 110px)',
    verticalAlign: 'bottom',
    marginBottom: 5,
  };

  return (
    <div>
      <img
        id="thumb"
        style={{
          height: 100,
          display: 'inline-flex',
        }}
        alt="Preview"
        src={url}
      />
      <FormControl sx={field}>
        <InputLabel shrink={false}>Image File</InputLabel>
        <Input
          id="file_reference"
          accept="image/*"
          type="file"
          hidden
          onChange={handleInput}
          inputProps={{ accept: 'image/*' }}
        />
      </FormControl>
    </div>
  );
};

export default FileUpload;
