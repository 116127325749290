import {
  graphQlFetch,
} from '../../Utilities/Utilities';

export const DASH_LOADING = 'DASH_LOADING';
export const dashboardLoadingAction = () => ({
  type: DASH_LOADING,
});
export const DASH_LOAD_ERROR = 'DASH_LOAD_ERROR';
export const dashboardLoadingErrorAction = (trigger, error) => ({
  type: DASH_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const getDashboard = (access, role, editable = false) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    let dashid = 0;
    if (role === 'administrator') {
      dashid = 1;
    } else if (role === 'authenticated') {
      dashid = 2;
    } else if (role === 'manager') {
      dashid = 3;
    } else if (role === 'employee') {
      dashid = 4;
    } else if (role === 'customer') {
      dashid = 5;
    }
    const query = `{
      dashboard(id: ${dashid}) {
        id
        uuid
        role
        xl
        lg
        md
        sm
        xs
      }
    }`;
    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.dashboard) {
      const layout = await result.data.dashboard;
      layout.xl = await JSON.parse(result.data.dashboard.xl);
      layout.lg = await JSON.parse(result.data.dashboard.lg);
      layout.md = await JSON.parse(result.data.dashboard.md);
      layout.sm = await JSON.parse(result.data.dashboard.sm);
      layout.xs = await JSON.parse(result.data.dashboard.xs);
      layout.xl = await layout.xl.map((i) => ({ ...i, isDraggable: true, isResizable: true }));
      layout.lg = await layout.lg.map((i) => ({ ...i, isDraggable: true, isResizable: true }));
      layout.md = await layout.md.map((i) => ({ ...i, isDraggable: true, isResizable: true }));
      layout.sm = await layout.sm.map((i) => ({ ...i, isDraggable: true, isResizable: true }));
      layout.xs = await layout.xs.map((i) => ({ ...i, isDraggable: true, isResizable: true }));
      if (!editable) {
        layout.xl = await layout.xl.map((i) => ({ ...i, isDraggable: false, isResizable: false }));
        layout.lg = await layout.lg.map((i) => ({ ...i, isDraggable: false, isResizable: false }));
        layout.md = await layout.md.map((i) => ({ ...i, isDraggable: false, isResizable: false }));
        layout.sm = await layout.sm.map((i) => ({ ...i, isDraggable: false, isResizable: false }));
        layout.xs = await layout.xs.map((i) => ({ ...i, isDraggable: false, isResizable: false }));
      }
      return dispatch({
        type: 'GET_DASHBOARD',
        data: layout,
      });
    }
    if (await !result.data.dashboard) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SET_DASHBOARD = 'SET_DASHBOARD';
export const setDashboard = (access, role, screenSize, layout) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const screenEdits = JSON.stringify(layout.substring(layout.indexOf('['), (layout.indexOf(']') + 1)));
    const data = `{
      "role": "${role}",
      "screenSize": "${screenSize}",
      "screenEdits": ${screenEdits}
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow',
    };

    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/dashboard_layout_resource`, requestOptions);

    if (await result.status === 200) {
      await dispatch(getDashboard(access, role, false));
      return dispatch({ type: 'SET_DASHBOARD' });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CARDS = 'GET_CARDS';
export const getCards = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_dashboard/dashboard_card_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const cards = result.dashboardCards;

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_CARDS',
        data: cards,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SET_ACTIVE_CARDS = 'SET_ACTIVE_CARDS';
export const setActiveCards = (access, role, activeCards) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);
    const data = `{
      "active": {
        "CreateKits": ${activeCards.CreateKits ? activeCards.CreateKits : false},
        "Billing": ${activeCards.Billing ? activeCards.Billing : false},
        "ManageKits": ${activeCards.ManageKits ? activeCards.ManageKits : false},
        "RequestKits": ${activeCards.RequestKits ? activeCards.RequestKits : false}
      },
      "layout": "${role}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow',
    };

    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/dashboard_card_resource`, requestOptions);

    if (result.status === 200) {
      await dispatch(setDashboard(access, role, false));
      return dispatch({
        type: 'SET_ACTIVE_CARDS',
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_GROWING_AREAS = 'GET_GROWING_AREAS';
export const getGrowingAreas = (access, setGrowingAreas, reloadDash, uid) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let loggedIn = '';
    if (access !== null) {
      myHeaders.append('Authorization', `Bearer ${access}`);
      loggedIn = 'yes';
    } else {
      loggedIn = 'no';
    }

    const body = `{
      "get": "customer",
      "id": "0",
      "uid": "${uid}",
      "logged_in": "${loggedIn}"
    }`;

    const requestOptions = {
      method: 'POST',
      body,
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      if (access !== null) {
        setGrowingAreas(result.growingAreaInfo);
        if (reloadDash) {
          reloadDash();
        }
      }
      return dispatch({
        type: 'GET_GROWING_AREAS',
        data: result.growingAreaInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// growingAreaKitAdd
export const GROWING_AREA_KIT_ADD = 'GROWING_AREA_KIT_ADD';
export const growingAreaKitAdd = (
  access,
  id,
  newKit,
  growingAreaKitError,
  customer,
  overwrite,
  uid,
  uuid,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let loggedIn = '';
    let currUid = '';
    let uuidSearch = 'no';
    if (access !== null) {
      myHeaders.append('Authorization', `Bearer ${access}`);
      loggedIn = 'yes';
      currUid = 0;
    } else {
      loggedIn = 'no';
      currUid = uid;
    }

    if (uuid === 'uuid') {
      uuidSearch = 'yes';
    }

    const body = `{
      "get": "kitAdd",
      "growing_area": "${id}",
      "kit_id": "${newKit}",
      "logged_in": "${loggedIn}",
      "id": "0",
      "customer": "${customer}",
      "overwrite": "${overwrite}",
      "uid": "${currUid}",
      "search_uuid": "${uuidSearch}"
    }`;

    const requestOptions = {
      method: 'POST',
      body,
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      growingAreaKitError(
        result.kitInvalid,
        result.kitTaken,
        newKit,
        result.overwrite,
        result.assigned,
      );
      return dispatch({
        type: 'GROWING_AREA_KIT_ADD',
        data: result.kit,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const CREATE_GROWING_AREA = 'CREATE_GROWING_AREA';
export const createGrowingArea = (
  access,
  areaName,
  area,
  landId,
  category,
  image,
  productPref,
  reloadDash,
  subCat,
  sqftEntryMethod,
  kitId,
  uid,
  growingAreaKitError,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let loggedIn = '';
    let currUid = '';
    if (access !== null) {
      myHeaders.append('Authorization', `Bearer ${access}`);
      loggedIn = 'yes';
      currUid = 0;
    } else {
      loggedIn = 'no';
      currUid = uid;
    }

    let cropType = '';
    if (landId === '2') {
      cropType = 'Lawn';
    } else if (landId === '3') {
      cropType = 'Garden';
    } else if (landId === '4') {
      cropType = 'Landscape';
    }
    let productType = '';
    if (productPref === '8' || productPref === 8) {
      productType = 'organic';
    } else if (productPref === '9' || productPref === 9) {
      productType = 'conventional';
    }

    let newName = '';
    if (areaName.includes("'")) {
      newName = areaName.replace("'", "/'");
    } else { newName = areaName; }

    const body = `{
      "name": "${newName}",
      "sqft": "${area}",
      "category": "${category}",
      "crop_type": "${cropType}",
      "product_type": "${productType}",
      "image": "${image}",
      "sub_category": "${subCat}",
      "sqft_entry_method": "${sqftEntryMethod}",
      "uid": "${currUid}",
      "logged_in": "${loggedIn}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await JSON.parse(await response.text());
    const growingAreaId = result.growingAreaInfo[0].value;

    if (await result.status === 200) {
      if (kitId && kitId !== '') {
        dispatch(growingAreaKitAdd(
          access,
          growingAreaId,
          kitId,
          growingAreaKitError,
          '',
          true,
          uid,
          'uuid',
        ));
      }
      if (reloadDash) {
        reloadDash();
      }
      return dispatch({
        type: 'CREATE_GROWING_AREA',
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_GROWING_AREA = 'SAVE_GROWING_AREA';
export const saveGrowingArea = (
  access,
  name,
  area,
  land,
  category,
  image,
  productTypeParam,
  editId,
  reloadDash,
  subCat,
  sqftEntryMethod,
  createAreaAs,
  saveSuccess,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let cropType = '';
    if (land === '2') {
      cropType = 'Lawn';
    } else if (land === '3') {
      cropType = 'Garden';
    } else if (land === '4') {
      cropType = 'Landscape';
    }
    let productType = '';
    if (productTypeParam === '8' || productTypeParam === 8) {
      productType = 'organic';
    } else if (productTypeParam === '9' || productTypeParam === 9) {
      productType = 'conventional';
    }
    if (!category) {
      // eslint-disable-next-line no-param-reassign
      category = '7';
    }

    // possessive on the name prevents save due to string ending earlier than anticipated

    // need to check if points and/or segments was modified before passing back
    // if modified, can stringify as through create.
    // if NOT, do not pass to prevent bad request response
    let body = [];
    let newName = '';
    if (name.includes("'")) {
      newName = name.replace("'", "/'");
    } else { newName = name; }

    body = `{
      "id": "${editId}",
      "name": "${newName}",
      "sqft": "${area}",
      "category": "${category}",
      "crop_type": "${cropType}",
      "product_type": "${productType}",
      "image": "${image}",
      "sub_category": "${subCat}",
      "sqft_entry_method": "${sqftEntryMethod}",
      "create_area_as": "${createAreaAs}",
      "get": ""
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      if (reloadDash) {
        reloadDash();
      }
      if (saveSuccess) {
        saveSuccess();
      }
      return dispatch({
        type: 'SAVE_GROWING_AREA',
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_GROWING_AREA = 'GET_GROWING_AREA';
export const getGrowingArea = (access, id) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: DASH_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "edit",
      "id": "${id}"
    }`;

    const requestOptions = {
      method: 'POST',
      body,
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_GROWING_AREA',
        data: result.growingAreaInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: DASH_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const updateCartQuantity = (quantity) => (
  function (dispatch) {
    const newQuantity = quantity;
    return dispatch({
      type: UPDATE_CART_QUANTITY,
      data: newQuantity,
    });
  }
);
