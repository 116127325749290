import {
  UTIL_LOADING,
  UTIL_LOAD_ERROR,
  GET_LOGGEDIN_USER,
  GET_PURCHASE_OWNERSHIP_STATUS,
} from './actions';

const initialState = {
  currentUser: null,
  responseOwnershipStatus: null,
  loading: false,
  loadError: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case UTIL_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case UTIL_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_LOGGEDIN_USER:
      return {
        ...state,
        currentUser: data,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case GET_PURCHASE_OWNERSHIP_STATUS:
      return {
        ...state,
        responseOwnershipStatus: data,
      };
    default:
      return state;
  }
}
