// import React, { useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOwnership } from './redux/actions';

const PurchaseOwnershipPoller = (props) => {
  const { parameters, callbackSet } = props;
  const purchaseUserLoginState = useSelector((state) => state.utilities.responseOwnershipStatus);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (purchaseUserLoginState !== null && (purchaseUserLoginState.last_login === 'never' || purchaseUserLoginState.last_login === 'exists')) {
      callbackSet(purchaseUserLoginState);
    } else if (purchaseUserLoginState !== null && purchaseUserLoginState.last_login === 'unknown' && retryAttempt < 5) {
      const purchaseId = parameters;
      setRetryAttempt(retryAttempt + 1);
      setTimeout(
        (purchaseId) => {
          dispatch(getPurchaseOwnership(purchaseId));
        },
        3000, purchaseId);
    } else if (purchaseUserLoginState !== null && purchaseUserLoginState.last_login === 'unknown' && retryAttempt >= 5) {
      callbackSet('timeout');
    }
  }, [purchaseUserLoginState]);

  useEffect(() => {
    if (parameters !== null) {
      dispatch(getPurchaseOwnership(parameters));
    }
  }, [parameters]);

  return (<></>);
};

export default PurchaseOwnershipPoller;
