import { graphQlFetch } from '../../Utilities/Utilities';
import { getProducts } from '../../Management/Store/redux/actions';
import { returnRelativeRole } from '../../UserSettings/UserHelper';

export const GROS_LOADING = 'GRO_LOADING';
export const grosmartLoadingAction = () => ({
  type: GROS_LOADING,
});

export const GROS_LOAD_ERROR = 'GRO_LOAD_ERROR';
export const grosmartLoadingErrorAction = (trigger, error) => ({
  type: GROS_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const SAMPLE_STATUS = 'SAMPLE_STATUS';
export const sampleStatus = (kitId, email, errorCheck) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    // may need to only send fields to save or determine default values
    const body = `{
      "sampleId": "${kitId}",
      "email": "${email}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_sample_status_resource`, requestOptions);
    const result = await response.json();

    // will want to use callback function to change page state before return dispatch

    if (await result.status === 200) {
      errorCheck(
        result.return[0].kInvalid,
        result.return[0].kAccessible,
        result.return[0].eInvalid,
        result.return[0].kStatus,
        // result.return[0].type,
        // result.return[0].customer,
      );
      return dispatch({
        type: 'SAMPLE_STATUS',
        data: result.return,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const RETRIEVE_SHIPPING_PDF = 'RETRIEVE_SHIPPING_PDF';
export const retrieveShippingPDF = (access, pdfType, pdfId, direction) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    let attributeId = '';
    let directionProperty = '';
    if (pdfType === 'kit') {
      attributeId = 'kit_id';
    } else if (pdfType === 'request') {
      attributeId = 'request_id';
    } else if (pdfType === 'order') {
      attributeId = 'order';
    }
    if (direction === 'customer') {
      directionProperty = '&direction=to_customer';
    }
    if (direction === 'lab') {
      directionProperty = '&direction=to_lab';
    }

    let pdfData = '';
    let result = '';
    let name = '';
    let arr = [];
    if (pdfType !== 'rerender') {
      const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_request_get_shipping_label?${attributeId}=${pdfId}${directionProperty}`, requestOptions);
      result = await JSON.parse(await response.text());
      pdfData = await result.pdf;
      name = await result.name;
      arr = { pdfData, name };
    }

    if ((await result.status === 200 || pdfType === 'rerender') && name) {
      return dispatch({
        type: 'RETRIEVE_SHIPPING_PDF',
        data: arr,
      });
    }
    if ((await result.status === 200 || pdfType === 'rerender') && !name) {
      return dispatch({
        type: 'RETRIEVE_SHIPPING_PDF',
        data: pdfData,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const RETRIEVE_STORE_SHIPPING_PDF = 'RETRIEVE_STORE_SHIPPING_PDF';
export const retrieveStoreShippingPDF = (access, pdfType, pdfId) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    let attributeId = '';
    if (pdfType === 'kit') {
      attributeId = 'kit_id';
    } else if (pdfType === 'order') {
      attributeId = 'order_id';
    }

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_order_get_shipping_label?${attributeId}=${pdfId}`, requestOptions);
    const result = await JSON.parse(await response.text());
    const pdfData = await result.pdf;

    if (await result.status === 200) {
      return dispatch({
        type: 'RETRIEVE_STORE_SHIPPING_PDF',
        data: pdfData,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS';
export const getCustomerOrders = (access) => (
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let body = `{
      "orders": "0"
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kits_by_order_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const orders = await result.orders;

    if (result.status === 200 || result.status === 201) {
      return dispatch({
        type: 'GET_CUSTOMER_ORDERS',
        data: orders,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const LOAD_RELATED_KITS = 'LOAD_RELATED_KITS';
export const loadRelatedKits = (access, request) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kits_by_request?request_id=${request.id}`, requestOptions);
    const result = await JSON.parse(await response.text());
    const kits = await result.kits;

    const kitResultArray = [];
    for (let i = 0; i < kits.length; i += 1) {
      const kitResultUnit = [];
      Object.entries(kits[i]).forEach((entry) => {
        const [key, value] = entry;
        if (value[0] && value[0].value) { // for single value fields
          kitResultUnit[key] = value[0].value;
        }
      });
      kitResultArray.push(kitResultUnit);
    }

    if (await result.status === 200) {
      return dispatch({
        type: 'LOAD_RELATED_KITS',
        data: kitResultArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const CREATE_KITS_BULK = 'CREATE_KITS_BULK';
export const createKitsInBulk = (access, request) => (
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let kitPrefix = -1;
    if (request.type === 'lawn') {
      kitPrefix = 0;
    } else if (request.type === 'garden') {
      kitPrefix = 1;
    } else if (request.type === 'landscape') {
      kitPrefix = 2;
    } else if (request.type === '') { // nhw 5/4/2023 if non-CA bulk kit creation fails check here
      request.type = 'kit';
    }

    let body = `{
      "business_id":"${request.request_business_id}",
      "business_prefix":"${request.request_business_prefix}",
      "num_o_kits":"${request.count}",
      "prefix":"${kitPrefix}",
      "request_id":"${request.id}",
      "type":"${request.type}"
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const kits = await result.kits;

    const kitResultArray = [];
    for (let i = 0; i < kits.length; i += 1) {
      const kitResultUnit = [];
      Object.entries(kits[i]).forEach((entry) => {
        const [key, value] = entry;
        if (value[0] && value[0].value) { // for single value fields
          kitResultUnit[key] = value[0].value;
        }
      });
      kitResultArray.push(kitResultUnit);
    }

    if (result.status === 200 || result.status === 201) {
      return dispatch({
        type: 'CREATE_KITS_BULK',
        data: kitResultArray,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const GET_REQUESTS = 'GET_REQUESTS';
export const getRequests = (access, isDateReturnFormatted, currentUser) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const query = `{
      requests {
        items {
          id
          uuid
          uid {
            id
            name
          }
          status
          type
          count
          created
          request_business {
            id
            name
            kit_prefix
          }
        }
      }
  }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.requests.items) {
      let requestArray = await result.data.requests.items;

      // filter down kits for managers
      if (currentUser && returnRelativeRole(currentUser) === 'manager') {
        requestArray = requestArray.filter((request) => request.request_business && request.request_business.length > 0 && currentUser.manager_businesses.includes(`${request.request_business[0].id}`));
      }

      // date formatting and sorting: true parameter will sort newest
      // to oldest and format to mm/dd/yyyy - hh:mm:ss
      if (isDateReturnFormatted) {
        requestArray.sort((a, b) => b.created - a.created);
        requestArray.forEach((entry) => {
          const requestDate = new Date(entry.created * 1000);
          const formattedData = `${requestDate.getMonth() + 1}/${requestDate.getDate()}/${requestDate.getFullYear()}`;
          const hours = requestDate.getHours();
          const minutes = `0${requestDate.getMinutes()}`;
          const seconds = `0${requestDate.getSeconds()}`;
          const formattedTime = `${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
          // eslint-disable-next-line no-param-reassign
          entry.created = `${formattedData} - ${formattedTime}`;
        });
      }

      return dispatch({
        type: 'GET_REQUESTS',
        data: requestArray,
        requestColumns: [
          { title: 'Business', field: 'request_business[0].name' },
          { title: 'User', field: 'uid[0].name' },
          { title: 'Type', field: 'type' },
          { title: 'Count', field: 'count' },
          { title: 'Status', field: 'status' },
          { title: 'Time', field: 'created' },
        ],
      });
    }
    if (await !result.data.requests.items) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const FINALIZE_REQUEST_APPROVAL = 'FINALIZE_REQUEST_APPROVAL';
export const finalizeRequestApproval = (access, request, status, currentUser) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    if (process.env.REACT_APP_SITE_SUPPORTS_UNIVERSAL === 'true' && process.env.REACT_APP_ADAPIFY_SITE_NAME !== 'Rx Soil Canada') {
      request.type = 'kit';
    }

    let body = `{
      "data": {
       "id": "${request.uuid}",
       "type": "adapify_request--adapify_request",
       "attributes": {
         "status": "${status}"
       }
     }
   }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_request/adapify_request/${request.uuid}`, requestOptions);

    if (await (result.status === 200)) {
      await dispatch(getRequests(access, true, currentUser)); // need to await update on table
      request.status = status;
      return dispatch({
        type: 'FINALIZE_REQUEST_APPROVAL',
        data: request,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// CLEAR_REQUEST_STATE
export const CLEAR_REQUEST_STATE = 'CLEAR_REQUEST_STATE';
export const clearRequestState = () => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    return dispatch({
      type: 'CLEAR_REQUEST_STATE',
    });
  }
);

export const CREATE_KIT_REQUEST = 'CREATE_KIT_REQUEST';
export const createKitRequest = (access, request, currentUser) => (
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    if (process.env.REACT_APP_SITE_SUPPORTS_UNIVERSAL === 'true' && process.env.REACT_APP_ADAPIFY_SITE_NAME !== 'Rx Soil Canada') {
      request.type = 'kit';
    }

    let body = `{
      "data": {
        "type": "adapify_request--adapify_request",
        "attributes": {
          "type": "${request.type}",
          "count": "${request.count}",
          "status": "pending"
        },
        "relationships": {
          "uid": {
            "data": [
              {
              "type": "user--user",
              "id": "${request.uid}"
            }]
          },
          "request_business": {
            "data": [
              {
              "type": "adapify_business--adapify_business",
              "id": "${request.request_business}"
            }]
          }
        }
      }
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_request/adapify_request`, requestOptions);

    if (result.status === 200 || result.status === 201) {
      await dispatch(getRequests(access, true, currentUser)); // need to await update on table
      return dispatch({
        type: 'CREATE_KIT_REQUEST',
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const GET_KITS = 'GET_KITS';
export const getKits = (access, currentUser) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const query = `{
      kits(limit:700, offset:1700) {
        items {
          id
          uuid
          soil_kit_id
          ssf_id
          tracking
          booking
          sold
          category
          type
          crop_id
          size
          sold
          results
          result_date
          kit_notes
          customer {
            id
            first_name
            last_name
            email
            phone
            customer_notes
          }
          business {
            id
            name
          }
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.kits.items) {
      let kitArray = await result.data.kits.items;

      // filter down kits for managers
      if (currentUser && returnRelativeRole(currentUser) === 'manager') {
        kitArray = kitArray.filter((kit) => kit.business && kit.business.length > 0 && currentUser.manager_businesses.includes(`${kit.business[0].id}`));
      }

      return dispatch({
        type: 'GET_KITS',
        data: kitArray,
        columns: [
          { title: 'Results', field: 'results' },
          { title: 'Sold', field: 'sold' },
          { title: 'Soil Kit ID', field: 'soil_kit_id' },
          { title: 'First Name', field: 'customer[0].first_name' },
          { title: 'Last Name', field: 'customer[0].last_name' },
          { title: 'Email', field: 'customer[0].email' },
          { title: 'Phone', field: 'customer[0].phone' },
          { title: 'Business', field: 'business[0].name' },
          { title: 'Tracking', field: 'tracking' },
          { title: 'Lab ID', field: 'ssf_id' },
          {
            title: 'ID',
            field: 'id',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Notes',
            field: 'customer_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Kit Notes',
            field: 'kit_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
        ],
      });
    }
    if (await !result.data.kits.items) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_ALL_KITS = 'GET_ALL_KITS';
export const getAllKits = (access, currentUser) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "all"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    // const kitArray = await JSON.parse(result.kitArray);
    // kitArray.result_date =
    // new Date(await Number(kitArray.result_date) * 1000).toLocaleDateString('en-US');

    // filter down kits for managers
    if (currentUser && returnRelativeRole(currentUser) === 'manager') {
      result.kitArray = result.kitArray.filter((kit) => kit.business_id && currentUser.manager_businesses.includes(`${kit.business_id}`));
    }

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_ALL_KITS',
        data: result.kitArray,
        columns: [
          { title: 'Results', field: 'results' },
          { title: 'Sold', field: 'sold' },
          { title: 'Soil Kit ID', field: 'soil_kit_id' },
          { title: 'Customer', field: 'customer_name' }, // customer
          { title: 'Email', field: 'email' }, // customer
          { title: 'Phone', field: 'phone' }, // customer
          { title: 'Business', field: 'business_name' }, // business
          { title: 'Tracking', field: 'tracking' },
          { title: 'Lab ID', field: 'ssf_id' },
          {
            title: 'ID',
            field: 'id',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Notes',
            field: 'customer_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Kit Notes',
            field: 'kit_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
        ],
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CUST_KITS = 'GET_CUST_KITS';
export const getCustKits = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "get": "customer"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_CUST_KITS',
        data: result.custKitArray,
        columns: [
          { title: 'Soil Kit ID', field: 'soil_kit_id' },
          { title: 'Results', field: 'results' },
          { title: 'Result Date', field: 'result_date' },
          {
            title: 'ID',
            field: 'id',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
        ],
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_KIT = 'GET_KIT';
export const getKit = (kitId) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kit_resource?soil_kit_id=${kitId}`, requestOptions);
    const result = await JSON.parse(await response.text());
    const kitArray = await JSON.parse(result.kit);
    if (kitArray.result_date) {
      kitArray.result_date = new Date(await Number(kitArray.result_date) * 1000).toLocaleDateString('en-US');
    }
    kitArray.created = new Date(await Number(kitArray.created) * 1000).toLocaleDateString('en-US');

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_KIT',
        data: kitArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_KIT_ACCESS = 'GET_KIT_ACCESS';
export const getKitAccess = (access) => ( // current user pulled in php
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kit_access`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_KIT_ACCESS',
        data: result.kitArray,
        columns: [
          { title: 'Results', field: 'results' },
          { title: 'Sold', field: 'sold' },
          { title: 'Soil Kit ID', field: 'soil_kit_id' },
          { title: 'Customer Name', field: 'name' },
          { title: 'Customer Email', field: 'email' },
          { title: 'Business', field: 'business' },
          { title: 'Tracking', field: 'tracking' },
          { title: 'Lab ID', field: 'ssf_id' },
          {
            title: 'ID',
            field: 'id',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Notes',
            field: 'customer_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
          {
            title: 'Kit Notes',
            field: 'kit_notes',
            cellStyle: {
              display: 'none',
            },
            headerStyle: {
              display: 'none',
            },
          },
        ],
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_KIT_CUSTOMER = 'SAVE_KIT_CUSTOMER';
export const saveKitCustomer = (
  access, kitId, customerId, firstName, lastName, addressOne, addressTwo,
  city, state, postal, email, phone, selected, currentUser, hasId,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    if (!hasId) {
      // eslint-disable-next-line no-param-reassign
      hasId = 0;
    }

    const body = `{
      "kit_id": "${kitId}",
      "first_name": "${firstName}",
      "last_name": "${lastName}",
      "address_one": "${addressOne}",
      "address_two": "${addressTwo}",
      "city": "${city}",
      "state": "${state}",
      "postal": "${postal}",
      "email": "${email}",
      "phone": "${phone}",
      "customer_id": "${customerId}",
      "selected": ${selected},
      "hasId": ${hasId}
    }`;

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      dispatch(getAllKits(access, currentUser));
      return dispatch({
        type: 'SAVE_KIT_CUSTOMER',
        // data: kitArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_KIT_BUSINESS = 'SAVE_KIT_BUSINESS';
export const saveKitBusiness = (access, kitId, businessId, currentUser) => (
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "kit_id": "${kitId}",
      "business_id": "${businessId}"
    }`;

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      dispatch(getAllKits(access, currentUser));
      return dispatch({
        type: 'SAVE_KIT_BUSINESS',
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const SELL_KIT = 'SELL_KIT';
export const sellKit = (access, kitId, currentUser) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "kit_id": "${kitId}",
      "sell": "true"
    }`;

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_kit_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      dispatch(getAllKits(access, currentUser));
      return dispatch({
        type: 'SELL_KIT',
        // data: kitArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_RECS = 'GET_RECS';
export const getRecs = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const query = `{
      recommendations {
        items {
          crop_id
          when_to_apply
          recommended_for
          product_for
          application_rate
          conditions
          type
          mfr_id
          validated
          product_reference
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.recommendations.items) {
      const recArray = await result.data.recommendations.items;
      return dispatch({
        type: 'GET_RECS',
        data: recArray,
        columns: [
          { title: 'Crop ID', field: 'crop_id' },
          { title: 'When to Apply', field: 'when_to_apply' },
          { title: 'Type', field: 'type' },
          { title: 'Validated', field: 'validated' },
        ],
      });
    }
    if (await !result.data.recommendations.items) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_PROD_REC = 'GET_PROD_REC';
export const getProdRec = (kitId, modify, id, triggerRefresh) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_algorithm_resource?kit_id=${kitId}&modify=${modify}&id=${id}`, requestOptions);
    const result = await JSON.parse(await response.text());
    const kitRecommendations = await result;

    if (await result.status === 200) {
      // need to modify result here possible if amend is sent
      if (triggerRefresh) {
        window.location.reload();
      } else {
        return dispatch({
          type: 'GET_PROD_REC',
          data: kitRecommendations,
        });
      }
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_KIT_SETTINGS = 'SAVE_KIT_SETTINGS';
export const saveKitSettings = (access, kitId, category, size, type, cropId, validationFail) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }

    let organic = 'conventional';
    if (type === true) {
      organic = 'organic';
    }
    let mySize = '';
    if (size) {
      mySize = Math.round(size);
    }
    const body = `{
      "type": "${organic}",
      "size": "${mySize}",
      "kit_id": "${kitId}",
      "crop_id": "${cropId}",
      "category": "${category}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/kit_settings_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
    // need to check for no access response for no admin or kit owner
    if (result.validated === 'false') {
      validationFail();
    } else {
      dispatch(getProdRec(kitId, 'yes', undefined, true));
    }
  }
);

export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const getRecommendations = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const query = `{
      recommendations {
        items {
          application_rate
          conditions
          crop_id
          how_to_apply
          id
          mfr_id
          product_for
          recommended_for
          type
          uuid
          validated
          when_to_apply
          product_reference {
            id
            display_name
            weight
            file_reference {
              url
            }
          }
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.recommendations.items) {
      const recList = await result.data.recommendations.items;
      return dispatch({
        type: 'GET_RECOMMENDATIONS',
        data: recList,
      });
    }
    if (await !result.data.recommendations.items) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const TOGGLE_REC_DIALOG = 'TOGGLE_REC_DIALOG';
export const toggleRecDialog = (data) => (
  function (dispatch) {
    let recData = data;
    if (!recData) {
      recData = {};
    }
    return dispatch({
      type: GROS_LOADING,
      data: recData,
    });
  }
);

export const TOGGLE_REQUEST_DETAILS_DIALOG = 'TOGGLE_REQUEST_DETAILS_DIALOG';
export const toggleRequestDetailsDialog = (data) => (
  function (dispatch) {
    let requestData = data;
    if (!requestData) {
      requestData = {};
    }
    return dispatch({
      type: TOGGLE_REQUEST_DETAILS_DIALOG, // GROS_LOADING,
      data: requestData,
    });
  }
);

export const GET_REC_INFO = 'GET_REC_INFO';
export const getRecInfo = (access, selectedRec, productId) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_recommendation_resource?selected=${selectedRec}&productId=${productId}`, requestOptions);
    const result = await response.json();
    const recInfo = await JSON.parse(result.recInfo);

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_REC_INFO',
        data: recInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const EDIT_REC = 'EDIT_REC';
export const editRec = (
  access,
  season,
  toApply,
  recFor,
  formula,
  appRate,
  productId,
  selectedRecs,
  recConditions,
  toAdd,
) => (
  // need the fields to save and all recs to save to
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    // may not like season being sent as an object, same possibly for app_rate and selectedRecs
    const whenToApply = [];
    Object.entries(season).forEach((value) => {
      if (value[1] === true) {
        if (value[0] === 'earlySpring') {
          whenToApply.push('EARLY SPRING');
        } else if (value[0] === 'spring') {
          whenToApply.push('SPRING');
        } else if (value[0] === 'lateSpring') {
          whenToApply.push('LATE SPRING');
        } else if (value[0] === 'earlySummer') {
          whenToApply.push('EARLY SUMMER');
        } else if (value[0] === 'summer') {
          whenToApply.push('SUMMER');
        } else if (value[0] === 'lateSummer') {
          whenToApply.push('LATE SUMMER');
        } else if (value[0] === 'earlyFall') {
          whenToApply.push('EARLY FALL');
        } else if (value[0] === 'fall') {
          whenToApply.push('FALL');
        } else if (value[0] === 'lateFall') {
          whenToApply.push('LATE FALL');
        } else if (value[0] === 'winter') {
          whenToApply.push('WINTER');
        }
      }
    });

    const selected = [];
    if (selectedRecs.cool === true) {
      selected.push('Cool Season Grass');
    }
    if (selectedRecs.warm === true) {
      selected.push('Warm Season Grass');
    }
    if (selectedRecs.veg === true) {
      selected.push('Vegetable Garden');
    }
    if (selectedRecs.fruit === true) {
      selected.push('Fruit Garden');
    }
    if (selectedRecs.flower === true) {
      selected.push('Flower Garden');
    }
    if (selectedRecs.land === true) {
      selected.push('Landscape');
    }
    if (selectedRecs.can === true) {
      selected.push('Cannabis');
    }

    const body = `{
      "when_to_apply": "${whenToApply}",
      "how_to_apply": "${toApply}",
      "recommended_for": "${recFor}",
      "product_for": "${formula}",
      "application_rate": "${appRate}",
      "product_reference": "${productId}",
      "selectedRecs": "${selected}",
      "rec_conditions": "${recConditions}"
    }`;

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_recommendation_resource`, requestOptions);
    const result = await response.json();
    const whatToAdd = await JSON.parse(result.whatToAdd);
    const names = await JSON.parse(result.names);

    if (await result.status === 200) {
      toAdd(whatToAdd, productId, names);
      await dispatch(getProducts(access, true)); // need to await update on table
      return dispatch({
        type: 'EDIT_REC',
        data: toAdd,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_REC = 'SAVE_REC';
export const saveRec = (access, productId, cropType) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let cropId = '';
    // can only create with crop_id and product_reference, need to calidate if fields
    // are the same in another way, can pass empty strings if those are the null values
    if (cropType === 'Cool Season Grass') {
      cropId = '2';
    } else if (cropType === 'Warm Season Grass') {
      cropId = '3';
    } else if (cropType === 'Vegetable Garden') {
      cropId = '4';
    } else if (cropType === 'Fruit Garden') {
      cropId = '5';
    } else if (cropType === 'Flower Garden') {
      cropId = '6';
    } else if (cropType === 'Landscape') {
      cropId = '7';
    } else if (cropType === 'Cannabis') {
      cropId = '8';
    }

    // may need to only send fields to save or determine default values
    let body = `{
      "crop_id": "${cropId}",
      "product_reference": "${productId}"
    }`;
    const ids = `${cropId};${productId}`;
    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_recommendation_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      await dispatch(getProducts(access, true)); // need to await update on table
      return dispatch({
        type: 'SAVE_REC',
        data: ids,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const DELETE_REC = 'DELETE_REC';
export const deleteRec = (access, productId, cropType) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let cropId = '';
    // can only create with crop_id and product_reference, need to calidate if fields are
    // the same in another way, can pass empty strings if those are the null values
    if (cropType === 'Cool Season Grass') {
      cropId = '2';
    } else if (cropType === 'Warm Season Grass') {
      cropId = '3';
    } else if (cropType === 'Vegetable Garden') {
      cropId = '4';
    } else if (cropType === 'Fruit Garden') {
      cropId = '5';
    } else if (cropType === 'Flower Garden') {
      cropId = '6';
    } else if (cropType === 'Landscape') {
      cropId = '7';
    } else if (cropType === 'Cannabis') {
      cropId = '8';
    }

    // may need to only send fields to save or determine default values
    let body = `{
      "crop_id": "${cropId}",
      "product_reference": "${productId}"
    }`;
    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_grosmart/grosmart_recommendation_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      await dispatch(getProducts(access, true)); // need to await update on table
      return dispatch({
        type: 'DELETE_REC',
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_GROWING_AREA = 'SAVE_GROWING_AREA';
export const saveGrowingArea = (
  access,
  latitude,
  longitude,
  selection,
  image,
  name,
  cropType,
  category,
  sqft,
  customer,
) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    // may need to only send fields to save or determine default values
    const body = `{
      "id": "0",
      "latitude": "${latitude}",
      "longitude": "${longitude}",
      "selection": "${selection}",
      "image": "${image}",
      "name": "${name}",
      "crop_type": "${cropType}",
      "category": "${category}",
      "sqft": "${sqft}",
      "customer": "${customer}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await response.json();
    const growingAreaInfo = await JSON.parse(result.growingAreaInfo);

    if (await result.status === 200) {
      return dispatch({
        type: 'SAVE_GROWING_AREA',
        data: growingAreaInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_CUSTOMER_NOTES = 'SAVE_CUSTOMER_NOTES';
export const saveCustomerNotes = (
  access,
  customerId,
  notes,
  currentUser,
) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    // may need to only send fields to save or determine default values
    const body = `{
      "id": "0",
      "customerId": "${customerId}",
      "notes": "${notes}",
      "get": "saveNotes"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      dispatch(getAllKits(access, currentUser));
      return dispatch({
        type: 'SAVE_CUSTOMER_NOTES',
        // data: notes,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_KIT_NOTES = 'SAVE_KIT_NOTES';
export const saveKitNotes = (
  access,
  notes,
  kitId,
  currentUser,
) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    // may need to only send fields to save or determine default values
    const body = `{
      "kitId": "${kitId}",
      "notes": "${notes}",
      "get": "saveKitNotes"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      dispatch(getAllKits(access, currentUser));
      return dispatch({
        type: 'SAVE_KIT_NOTES',
        data: result.kitNotes,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const EDIT_GROWING_AREA = 'EDIT_GROWING_AREA';
export const editGrowingArea = (
  access,
  latitude,
  longitude,
  selection,
  image,
  name,
  cropType,
  category,
  sqft,
  id,
) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    // may need to only send fields to save or determine default values
    const body = `{
      "id": "${id}",
      "latitude": "${latitude}",
      "longitude": "${longitude}",
      "selection": "${selection}",
      "image": "${image}",
      "name": "${name}",
      "crop_type": "${cropType}",
      "category": "${category}",
      "sqft": "${sqft}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await response.json();
    const growingAreaInfo = await JSON.parse(result.growingAreaInfo);

    if (await result.status === 200) {
      return dispatch({
        type: 'EDIT_GROWING_AREA',
        data: growingAreaInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const AMAZON_VALIDATE = 'AMAZON_VALIIDATE';
export const amazonValidate = (kitId, email, errorCheck) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    // may need to only send fields to save or determine default values
    const body = `{
      "kitId": "${kitId}",
      "email": "${email}",
      "get": "amazon_validate"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_kit_resource`, requestOptions);
    const result = await response.json();

    // will want to use callback function to change page state before return dispatch

    if (await result.status === 200) {
      errorCheck(
        result.return[0].kInvalid,
        result.return[0].kTaken,
        result.return[0].eInvalid,
        result.return[0].type,
        result.return[0].customer,
      );
      return dispatch({
        type: 'AMAZON_VALIDATE',
        data: result.return,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const AMAZON_GROWING_AREA = 'AMAZON_GROWING_AREA';
export const amazonGrowingArea = (
  kitId,
  type,
  area,
  category,
  subCategory,
  productType,
  customer,
) => (
  // need to provide only the crop_id and product reference
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: GROS_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    // may need to only send fields to save or determine default values
    const body = `{
      "name": "Growing Area",
      "kitId": "${kitId}",
      "type": "${type}",
      "area": "${area}",
      "category": "${category}",
      "subCategory": "${subCategory}",
      "productType": "${productType}",
      "customer": "${customer}",
      "get": "amazon_growing_area"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/grosmart_growing_area`, requestOptions);
    const result = await response.json();

    // will want to use callback function to change page state before return dispatch

    if (await result.status === 200) {
      return dispatch({
        type: 'AMAZON_GROWING_AREA',
        data: result.return,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: GROS_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// an action, get_cust_recs, needs to be added that will gather all of the customer's
// past recommendations and save it to the data block: (state) => state.rxsoil.recommendationsArr
