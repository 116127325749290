import {
  MANA_LOADING,
  MANA_LOAD_ERROR,
  GET_CUSTOMERS,
  GET_CUSTOMER,
  GET_CUSTOMER_INFO,
  VALID_EMAIL,
  TOGGLE_CUSTOMER_DIALOG,
  GET_CUSTOMERS_INFO,
  MERGE_CUSTOMERS,
} from './actions';

const initialState = {
  columns: [],
  customer: [],
  customers: [],
  customerInfo: [],
  bothCustInfo: [],
  loading: false,
  loadError: false,
  customerDialogOpen: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const { type, columns, data } = action;
  switch (type) {
    case MANA_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case MANA_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        columns,
        customers: data,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: data,
      };
    case GET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: data,
      };
    case VALID_EMAIL:
      return {
        ...state,
      };
    case TOGGLE_CUSTOMER_DIALOG:
      return {
        ...state,
        customer: data,
        customerDialogOpen: !state.customerDialogOpen,
      };
    case GET_CUSTOMERS_INFO:
      return {
        ...state,
        bothCustInfo: data,
      };
    case MERGE_CUSTOMERS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
