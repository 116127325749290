import {
  SETTINGS_LOADING,
  SETTINGS_LOAD_ERROR,
  UPDATE_ACC,
  SAVE_ALERTS,
  GET_ORDERS,
} from './actions';

const initialState = {
  columns: [],
  orders: [],
  loading: false,
  loadError: false,
  errorData: null,
};

export default function (state = initialState, action) {
  const { type, columns, data } = action;
  switch (type) {
    case SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case SETTINGS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case UPDATE_ACC:
      return {
        ...state,
      };
    case SAVE_ALERTS:
      return {
        ...state,
      };
    case GET_ORDERS:
      return {
        ...state,
        columns,
        orders: data,
      };
    default:
      return state;
  }
}
