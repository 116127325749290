// React Redux Core
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI and other Libraries
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  Slide,
  // Button,
} from '@mui/material';
// Icons
// import AccountCircle from '@mui/icons-material/AccountCircle';
// Adapify
import { useRefreshToken } from 'react-auth-kit';
import UserMenu from './UserMenu';
import SideMenu from './SideMenu';
import StoreCart from '../Store/StoreCart';
import { getLoggedinUser, utilitiesLoadingErrorAction } from '../Utilities/redux/actions';
import { RefreshComponent } from '../Utilities/Utilities';
import theme from '../theme';

/*
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  userMenu: {
    marginLeft: 'auto',
  },
  appBar: {
    backgroundColor: theme.palette.appBar.light,
    color: theme.palette.appBar.lightFont,
    borderBottom: theme.palette.appBar.lightBorder,
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  anonAppBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
  },
  anonGro: {
    maxWidth: 200,
    marginTop: 10,
  },
}));
*/

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    </>
  );
}

const Header = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const {
    hideOnScroll,
    checkout,
    updateCheckout,
  } = props;
  const user = useSelector((state) => state.utilities.currentUser);
  const reftoken = useRefreshToken();
  const { authToken } = reftoken.getCurrentAuthState();
  let access = '';
  if (!authToken) { RefreshComponent(); } else { access = authToken; }
  const buttCont = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  };
  const appBar = {
    backgroundColor: theme.palette.appBar.lightBg,
    color: theme.palette.appBar.lightFont,
    borderBottom: theme.palette.appBar.lightBorder,
  };

  const load = useCallback(async () => {
    if (!user) {
      try {
        await dispatch(getLoggedinUser(access));
      } catch (error) {
        await dispatch(utilitiesLoadingErrorAction('getLoggedinUser', error.message));
      }
    }
  }, [user, access, dispatch]);

  useEffect(() => {
    function setData() {
      if (!user) {
        load();
      }
    }
    setData();
  }, [user, load]);

  const burgerBar = (
    <AppBar sx={appBar}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <SideMenu />
          <img style={{ maxHeight: '45px', margin: '0 8px' }} src={`${process.env.PUBLIC_URL}/logo-wide.png`} alt="logo" />
        </div>
        <div style={buttCont}>
          {(process.env.REACT_APP_SITE_SUPPORTS_STORE === 'true')
            ? (
              <StoreCart
                sx={{ marginBottom: '1%' }}
                checkout={checkout}
                updateCheckout={updateCheckout}
              />
            )
            : (<></>)}
          {((user !== null) && (user.uid > 0))
            ? (
              <div
                style={{
                  marginLeft: 'auto',
                }}
              >
                <UserMenu />
              </div>
            ) : (
              <></>
              /* <div
                style={{
                  display: 'flex',
                  margin: '6px 5px',
                }}
              >
                <Button
                  href="/"
                  variant="outlined"
                  sx={{
                    margin: theme.spacing(1, 1.5),
                  }}
                >
                  Login
                  <AccountCircle />
                </Button>
              </div>
              */
            )}
        </div>
      </Toolbar>
    </AppBar>
  );

  return (
    <>
      <CssBaseline />
      {(hideOnScroll)
        ? (<HideOnScroll {...props}>{burgerBar}</HideOnScroll>)
        : (<>{burgerBar}</>)}
      <Toolbar style={{ display: window.location.pathname === '/management' ? 'none' : 'block' }} />
    </>
  );
};

export default Header;
