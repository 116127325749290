import { graphQlFetch } from '../../../../Utilities/Utilities';

export const MANA_LOADING = 'MANA_LOADING';
export const managementLoadingAction = () => ({
  type: MANA_LOADING,
});
export const MANA_LOAD_ERROR = 'MANA_LOAD_ERROR';
export const managementLoadingErrorAction = (trigger, error) => ({
  type: MANA_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const TOGGLE_USER_DIALOG = 'TOGGLE_USER_DIALOG';
export const toggleUserDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }
    return dispatch({
      type: TOGGLE_USER_DIALOG,
      data: rowData,
    });
  }
);

export const TOGGLE_EDIT_DIALOG = 'TOGGLE_EDIT_DIALOG';
export const toggleEditDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }
    return dispatch({
      type: TOGGLE_EDIT_DIALOG,
      data: rowData,
    });
  }
);

export const GET_USERS = 'GET_USERS';
export const getUsers = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const query = `{
      users {
        items {
          id
          name
          mail
          roles {
            role
          }
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.users.items) {
      const userArray = await result.data.users.items.filter((x) => !!x);
      userArray.map((user) => {
        if (user.roles) {
          const userRoles = user.roles.map((e) => (
            e.role
          )).join(', ');
          /* eslint no-param-reassign: ["error", { "props": false }] */
          user.roles = userRoles;
        }
        return true;
      });
      userArray.shift();
      return dispatch({
        type: 'GET_USERS',
        data: userArray,
        columns: [
          { title: 'ID', field: 'id' },
          { title: 'Username', field: 'name' },
          { title: 'Email', field: 'mail' },
          { title: 'Roles', field: 'roles' },
        ],
      });
    }
    if (await !result.data.users.items) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_USER = 'GET_USER';
export const getUser = (access, data) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_core/create_user_resource?user_id=${data.uid}`, requestOptions);
    const result = await JSON.parse(await response.text());
    // const productArray = await JSON.parse(result.customer);

    const userResultArray = [];
    Object.entries(result.user).forEach((entry) => {
      const [key, value] = entry;
      if (value[0]) {
        userResultArray[key] = value[0].value;
      }
    });

    // var as = Object.values(result.customer);
    // const result = response.json();

    if (await result.status === 200) {
      // await dispatch(setCustomer(result.customer));
      return dispatch({
        type: 'GET_USER',
        data: userResultArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// need to validate if email is taken in its own action
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'; // need to make sure viewed user email is omitted
export const validateEmail = (access, email, id, mailTaken) => (
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
        "id": "${id}",
        "email": "${email}"
      }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/create_user_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      mailTaken(false);
      return dispatch({
        type: 'VALIDATE_EMAIL',
      });
    }
    if (result.status === 400) {
      mailTaken(true);
    }
    const err = result;
    return dispatch({
      type: MANA_LOAD_ERROR,
      data: err,
    });
  }
);

export const CREATE_USER = 'CREATE_USER';
export const createUser = (access, username, email, roles, notify) => (
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    let body = `{
        "name": "${username}",
        "mail": "${email}",
        "site_admin": "${roles.site_admin}",
        "manager": "${roles.manager}",
        "employee": "${roles.employee}",
        "customer": "${roles.customer}",
        "notify": "${notify}"
      }`;
    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/create_user_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      await dispatch(getUsers(access));
      // set form back to empty
      return dispatch({
        type: 'CREATE_USER',
      });
    }
    const err = result;
    return dispatch({
      type: MANA_LOAD_ERROR,
      data: err,
    });
  }
);

export const EDIT_USER = 'EDIT_USER';
export const editUser = (access, username, email, roles, uid) => (
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    let body = '';
    if (roles !== '') {
      body = `{
          "name": "${username}",
          "mail": "${email}",
          "site_admin": "${roles.site_admin}",
          "manager": "${roles.manager}",
          "employee": "${roles.employee}",
          "customer": "${roles.customer}",
          "uid": "${uid}"
        }`;
    } else {
      body = `{
        "name": "${username}",
        "uid": "${uid}",
        "mail": ""
      }`;
    }
    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_core/create_user_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      await dispatch(getUsers(access));
      return dispatch({
        type: 'EDIT_USER',
      });
    }
    const err = result;
    return dispatch({
      type: MANA_LOAD_ERROR,
      data: err,
    });
  }
);
