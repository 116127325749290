export const UTIL_LOADING = 'LOADING';
export const utilitiesLoadingAction = () => ({
  type: UTIL_LOADING,
});
export const UTIL_LOAD_ERROR = 'LOAD_ERROR';
export const utilitiesLoadingErrorAction = (trigger, error) => ({
  type: UTIL_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const getLoggedinUser = (access, validate) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: UTIL_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_core/current_user_resource`, requestOptions);
    const result = await response.json();
    const currentUser = await JSON.parse(await result);
    currentUser.email_alert_settings = await JSON.parse(await currentUser.email_alert_settings);
    currentUser.push_alert_settings = await JSON.parse(await currentUser.push_alert_settings);

    if (response.status === 200) {
      if (currentUser.role !== 'anonymous' && validate) {
        validate();
      }
      localStorage.setItem('_auth_state', JSON.stringify(currentUser));
      return dispatch({
        type: 'GET_LOGGEDIN_USER',
        data: currentUser,
      });
    }
    if (response.ok === false) {
      const err = response;
      return dispatch({
        type: UTIL_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_PURCHASE_OWNERSHIP_STATUS = 'GET_PURCHASE_OWNERSHIP_STATUS';
export const getPurchaseOwnership = (confirmationId) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: UTIL_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_store/adapify_order_resource?method=last_login_check&confirmation_id=${confirmationId}`, requestOptions);
    const result = await response.json();

    if (result.status === 200) {
      return dispatch({
        type: 'GET_PURCHASE_OWNERSHIP_STATUS',
        data: result,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: UTIL_LOAD_ERROR,
        data: err,
      });
    }
  }
);
