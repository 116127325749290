import { graphQlFetch } from '../../../../Utilities/Utilities';

export const MANA_LOADING = 'MANA_LOADING';
export const managementLoadingAction = () => ({
  type: MANA_LOADING,
});
export const MANA_LOAD_ERROR = 'MANA_LOAD_ERROR';
export const managementLoadingErrorAction = (trigger, error) => ({
  type: MANA_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});

export const TOGGLE_CUSTOMER_DIALOG = 'TOGGLE_CUSTOMER_DIALOG';
export const toggleCustomerDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }

    return dispatch({
      type: TOGGLE_CUSTOMER_DIALOG,
      data: rowData,
    });
  }
);

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const getCustomers = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const query = `{
      customers {
        items {
          id
          uuid
          first_name
          last_name
          address_1
          phone
          city
          state
          postal
          email
        }
      }
    }`;

    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.customers.items) {
      const customerArray = await result.data.customers.items.filter((x) => !!x);

      return dispatch({
        type: 'GET_CUSTOMERS',
        data: customerArray,
        columns: [
          { title: 'ID', field: 'id' },
          { title: 'First Name', field: 'first_name' },
          { title: 'Last Name', field: 'last_name' },
          { title: 'Address', field: 'address_1' },
          { title: 'Phone', field: 'phone' },
          { title: 'City', field: 'city' },
          { title: 'State', field: 'state' },
          { title: 'Zip', field: 'postal' },
          { title: 'Email', field: 'email' },
        ],
      });
    }
    if (await !result.data.customers.items) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const getCustomer = (access, data, id) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    if (data.id) {
      // eslint-disable-next-line no-param-reassign
      id = data.id;
    }

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_customer/adapify_customer_resource?customer_id=${id}`, requestOptions);
    const result = await JSON.parse(await response.text());

    const customerResultArray = [];
    Object.entries(result.customer).forEach((entry) => {
      const [key, value] = entry;
      if (value[0]) {
        customerResultArray[key] = value[0].value;
      }
    });

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_CUSTOMER',
        data: customerResultArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const editCustomer = (access, customer) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const dob = new Date(customer.date_of_birth);
    const dobTimestamp = Math.floor(dob.getTime() / 1000);

    let body = `{
      "data": {
        "id": "${customer.uuid}",
        "type": "adapify_customer--adapify_customer",
        "attributes": {
          "first_name": "${customer.first_name}",
          "last_name": "${customer.last_name}",
          "address_1": "${customer.address_1}",
          "address_2": "${customer.address_2}",
          "city": "${customer.city}",
          "state": "${customer.state}",
          "postal": "${customer.postal}",
          "email": "${customer.email}",
          "date_of_birth": "${dobTimestamp}",
          "type": "${customer.type}",
          "source": "${customer.source}",
          "phone": "${customer.phone}",
          "accounting_id": "${customer.accounting_id}"
        }
      }
    }`;
    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_customer/adapify_customer/${customer.uuid}`, requestOptions);

    if (await result.status === 200) {
      await dispatch(getCustomers(access)); // need to await update on table
      return dispatch({
        type: 'EDIT_CUSTOMER',
        data: customer.uuid,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const saveCustomer = (access, customer) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const dob = new Date(customer.date_of_birth);
    const dobTimestamp = Math.floor(dob.getTime() / 1000);

    let body = `{
      "data": {
        "type": "adapify_customer--adapify_customer",
        "attributes": {
          "first_name": "${customer.first_name}",
          "last_name": "${customer.last_name}",
          "address_1": "${customer.address_1}",
          "address_2": "${customer.address_2}",
          "city": "${customer.city}",
          "state": "${customer.state}",
          "postal": "${customer.postal}",
          "email": "${customer.email}",
          "date_of_birth": "${dobTimestamp}",
          "type": "${customer.type}",
          "source": "${customer.source}",
          "accounting_id": "${customer.accounting_id}",
          "phone": "${customer.phone}"
        }
      }
    }`;

    body = JSON.stringify(JSON.parse(body));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/jsonapi/adapify_customer/adapify_customer`, requestOptions);

    if (result.status === 200 || result.status === 201) {
      await dispatch(getCustomers(access)); // need to await update on table
      return dispatch({
        type: 'SAVE_CUSTOMER',
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

// need to validate if email is taken in its own action
export const VALID_EMAIL = 'VALID_EMAIL'; // need to make sure viewed user email is omitted
export const validEmail = (access, email, mailValid) => (
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
        "email": "${email}",
        "find": "true"
      }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/create_user_resource`, requestOptions);
    const result = await response.json();

    if (await result.status === 200) {
      mailValid(true);
      return dispatch({
        type: 'VALID_EMAIL',
      });
    }
    if (result.status === 400) {
      mailValid(false);
    }
    const err = result;
    return dispatch({
      type: MANA_LOAD_ERROR,
      data: err,
    });
  }
);

export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO';
export const getCustomerInfo = (access, account) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "account": "${account}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_customer_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      return dispatch({
        type: 'GET_CUSTOMER_INFO',
        data: result.customerInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CUSTOMERS_INFO = 'GET_CUSTOMERS_INFO';
export const getCustomersInfo = (access, removeAcc, keepAcc) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_customer/multiple_customers_resource?removeAcc=${removeAcc}&keepAcc=${keepAcc}`, requestOptions);
    const result = await JSON.parse(await response.text());
    const bothCustInfo = [];

    if (result.status === 200) {
      const customerOneView = result.customerOne;
      const customerTwoView = result.customerTwo;
      const customerOneOrders = result.removeOrders;
      const customerTwoOrders = result.keepOrders;
      const customerOneAreas = result.removeAreas;
      const customerTwoAreas = result.keepAreas;
      bothCustInfo.push(customerOneView);
      bothCustInfo.push(customerTwoView);
      bothCustInfo.push(customerOneOrders);
      bothCustInfo.push(customerTwoOrders);
      bothCustInfo.push(customerOneAreas);
      bothCustInfo.push(customerTwoAreas);
      return dispatch({
        type: 'GET_CUSTOMERS_INFO',
        data: bothCustInfo,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const MERGE_CUSTOMERS = 'MERGE_CUSTOMERS';
export const mergeCustomers = (access, thisAccountRemove, thisAccountKeep) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: MANA_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "removeAcc": "${thisAccountRemove}",
      "keepAcc": "${thisAccountKeep}"
    }`;

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_customer/multiple_customers_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (result.status === 200) {
      return dispatch({
        type: 'MERGE_CUSTOMERS',
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: MANA_LOAD_ERROR,
        data: err,
      });
    }
  }
);
