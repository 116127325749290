import { useEffect, useCallback } from 'react';
import { useIsAuthenticated, useRefreshToken, useSignOut } from 'react-auth-kit';
import { useDispatch } from 'react-redux';
import { utilitiesLoadingErrorAction } from './redux/actions';

const RefreshComponent = () => {
  const dispatch = useDispatch();
  const signOut = useSignOut();
  const isAuth = useIsAuthenticated();
  const isAuthenticated = isAuth();
  const refState = useRefreshToken(); // Create the refresh token object

  const refreshFunction = useCallback(async (token) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'refresh_token');
    urlencoded.append('client_id', `${process.env.REACT_APP_ADAPIFY_CLIENT_ID}`);
    urlencoded.append('client_secret', `${process.env.REACT_APP_ADAPIFY_CLIENT_SECRET}`);
    urlencoded.append('refresh_token', token);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/oauth/token`, requestOptions);
    if (response.status === 200) {
      const result = await response.json();
      // Setting the auth state
      refState.updateAuthState(await result.access_token, 'Bearer', 10080); // in minutes
      refState.updateRefreshToken(await result.refresh_token, 20160);
    }
    if (response.status !== 200) {
      const err = response;
      return dispatch(utilitiesLoadingErrorAction('AuthIssue', err));
    }
    return null;
  }, [dispatch, refState]);

  useEffect(() => {
    const refToken = localStorage.getItem('_auth_refresh');
    function fetchData() {
      if (!isAuthenticated) {
        if ((refToken === null) || (refToken === undefined)) {
          signOut();
        } else {
          refreshFunction(refToken);
        }
      } else if (refState.getCurrentRefreshToken()) {
        refreshFunction(refState.getCurrentRefreshToken());
      } else {
        signOut();
        localStorage.setItem('_auth', null);
        localStorage.setItem('_auth_refresh', null);
        window.location.reload(false);
      }
    }
    fetchData();
  }, [dispatch, refState, isAuthenticated, refreshFunction, signOut]);
};

export default RefreshComponent;
