import { combineReducers } from 'redux';

import utilities from './Utilities/redux/reducer';
import dashboard from './Dashboard/redux/reducer';
import management from './Management/Users/Users/redux/reducer';
import loginRegister from './LoginRegister/redux/reducer';
import mgmtStore from './Management/Store/redux/reducer';
import rxsoil from './RxSoil/redux/reducer';
import mgmtBusiness from './Management/Businesses/redux/reducer';
import mgmtCustomer from './Management/Users/Customers/redux/reducer';
import userSettings from './UserSettings/redux/reducer';
import store from './Store/redux/reducer';

const rootReducer = combineReducers({
  utilities,
  loginRegister,
  dashboard,
  management,
  mgmtStore,
  rxsoil,
  mgmtCustomer,
  mgmtBusiness,
  userSettings,
  store,
});

export default rootReducer;
