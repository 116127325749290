import {
  DASH_LOADING,
  DASH_LOAD_ERROR,
  GET_DASHBOARD,
  SET_DASHBOARD,
  GET_CARDS,
  SET_ACTIVE_CARDS,
  GET_GROWING_AREAS,
  GET_GROWING_AREA,
  GROWING_AREA_KIT_ADD,
  CREATE_GROWING_AREA,
  SAVE_GROWING_AREA,
  UPDATE_CART_QUANTITY,
} from './actions';

const initialState = {
  layouts: null,
  cards: null,
  kit: null,
  loading: false,
  loadError: false,
  errorData: null,
  newQuantity: null,
  growingAreas: [],
  growingAreaInfo: [],
  userAvailability: [],
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case DASH_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case DASH_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        layouts: data,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case SET_DASHBOARD:
      return {
        ...state,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case GET_CARDS:
      return {
        ...state,
        cards: data,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case SET_ACTIVE_CARDS:
      return {
        ...state,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case GET_GROWING_AREAS:
      return {
        ...state,
        growingAreas: data,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case GET_GROWING_AREA:
      return {
        ...state,
        growingAreaInfo: data,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case GROWING_AREA_KIT_ADD:
      return {
        ...state,
        kit: data,
      };
    case CREATE_GROWING_AREA:
      return {
        ...state,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case SAVE_GROWING_AREA:
      return {
        ...state,
        loading: false,
        loadError: false,
        errorData: null,
      };
    case UPDATE_CART_QUANTITY:
      return {
        ...state,
        newQuantity: data,
      };
    default:
      return state;
  }
}
