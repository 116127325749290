const saveToLS = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const returnRelativeDashboardRole = (user) => {
  let role = '';
  // cascading assignment sequence: final priority priviledge is assigned to user.  Order matters!
  user.roles.forEach((userRole) => { if (userRole === 'authenticated') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'customer') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'employee') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'manager') { role = userRole; } });
  saveToLS('viewAsRole', role);
  return role;
};

export const returnRelativeRole = (user) => {
  let role = '';
  // cascading assignment sequence: final priority priviledge is assigned to user.  Order matters!
  user.roles.forEach((userRole) => { if (userRole === 'authenticated') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'customer') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'employee') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'manager') { role = userRole; } });
  user.roles.forEach((userRole) => { if (userRole === 'administrator') { role = userRole; } });
  saveToLS('viewAsRole', role);
  return role;
};
