// React Redux Core
import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { AuthProvider } from 'react-auth-kit';
// MUI and other Libraries
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// Adapify
import rootReducer from './rootReducer';
import theme from './theme';
import App from './App';

// const rootElement = document.getElementById('root');
const middleware = [thunk, logger];//  ||
const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware)),
);

const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactDOM.render(
root.render(
  <AuthProvider
    authType="localstorage"
    authName="_auth"
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === 'https:'}
    refreshToken
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </AuthProvider>,
);
